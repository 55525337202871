import { ErrorCode } from "../../models/applicationState";
import { Action, Dispatch } from "redux";
import { createAction, createPayloadAction, IPayloadAction } from "../../redux/actions/actionCreators";
import { ActionTypes } from "../../redux/actions/actionTypes";
import { LoginService } from "./loginService";
import { showErrorAction } from "../../redux/actions/appErrorActions";
import { strings } from "../../common/strings";
import { IUser } from "./user";

export default interface ILoginActions {
    login(user: IUser): Promise<IUser>;
    logout(): void;
}

export interface ILoginSuccessAction extends IPayloadAction<string, IUser> {
    type: ActionTypes.LOGIN_SUCCESS;
}

export interface ILogoutAction extends Action<string> {
    type: ActionTypes.LOGOUT;
}

export function login(user: IUser): (dispatch: Dispatch) => Promise<IUser> {
    return async (dispatch: Dispatch) => {
        const loginService = new LoginService();
        const userResponse = await loginService.login(user);

        if (userResponse) {
            dispatch(loginSuccess(userResponse));
        } else {
            dispatch(showErrorAction({
                errorCode: ErrorCode.AuthFailed,
                message: strings.login.authFailedMessage,
                title: strings.login.authFailedTitle
            }));
        }

        return userResponse;
    };
}

export function logout(): (dispatch: Dispatch) => void {
    return (dispatch: Dispatch): void => {
        dispatch({type: ActionTypes.LOGOUT});
    };
}


export const loginSuccess = createPayloadAction<ILoginSuccessAction>(ActionTypes.LOGIN_SUCCESS);

export const logoutAction = createAction<ILogoutAction>(ActionTypes.LOGOUT);
