import React from "react";
import { IApplicationState } from "../../models/applicationState";
import IExternalProjectActions, * as externalProjectActions from "./externalProjectActions";
import { bindActionCreators } from 'redux'
import { connect } from "react-redux";
import { strings } from "../../common/strings";
import { RouteComponentProps, withRouter } from "react-router";
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-bootstrap-table/dist/react-bootstrap-table-all.min.css';
// import 'react-day-picker/lib/style.css';
import './externalProjects.scss';
import {IUser, CAN_MANAGE_PROCESSES} from "../login/user";
import { ExternalProjectsService } from "./externalProjectsService";
import { IExternalProject } from "./externalProject";
import { TomcomBackendService } from "../tomcomBackendService";
import moment from 'moment';
import MomentLocaleUtils from 'react-day-picker/moment';
import 'moment/locale/en-gb';
import 'moment/locale/de';
import 'moment/locale/es';
import qs from "query-string";
import sscache from "session-storage-cache";

import Button from "react-bootstrap/Button";
import {BackofficeStatus} from "./backofficeStatus";
import _ from "lodash";


interface IExternalProjectsProps extends RouteComponentProps {
    actions: IExternalProjectActions;
    user: IUser
}

interface IExternalProjectsUrlParams {
    dateFrom: string;
    dateTo: string;
    vin: string;
    backofficeStatus?: string;
}


interface IExternalProjectsState {
    isFetching: boolean;
    data?: IExternalProject[];
    params: IExternalProjectsUrlParams;
}

function mapStateToProps(state: IApplicationState) {
    return {
        user: state.currentUser
    }
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(externalProjectActions, dispatch),
    };
}

const DATE_FORMAT = 'YYYY-MM-DD';
const sessionStoragePrefix = "externalProjects";
const dateFromKey = "dateFrom";
const dateToKey = "dateTo";
const vinKey = "vin";
const backofficeStatusKey = "backofficeStatus";
const projectsKey = sessionStoragePrefix + "List";
const cacheExpiryMinutes = 10;

@connect(mapStateToProps, mapDispatchToProps)
@(withRouter as any)
export default class ExternalProjectsPage extends React.Component<IExternalProjectsProps, IExternalProjectsState> {

    private externalProjectService: ExternalProjectsService;
    private ignoreComponentDidUpdateOnce: boolean = false;

    constructor(props: IExternalProjectsProps, context: any) {
        super(props, context);

        this.state = {
            isFetching: true,
            params: this.getParamsFromUrlOrSession(),
        };
        this.externalProjectService = new ExternalProjectsService(this.props.user);
    }

    private getParamsFromUrlOrSession(): IExternalProjectsUrlParams {
        const queryParams = qs.parse(this.props.location.search);
        const today = moment(new Date()).format(DATE_FORMAT);
        const params = {
            dateFrom: (queryParams[dateFromKey] || sessionStorage.getItem(sessionStoragePrefix + dateFromKey) || today) as string,
            dateTo: (queryParams[dateToKey] || sessionStorage.getItem(sessionStoragePrefix + dateToKey) || today) as string,
            //wenn url-query vorhanden ist, vin=undefined zulassen, sonst spring immer zuletzt verwendeter Wert aus Session ein
            vin: this.props.location.search && this.props.location.search.length > 0
                ? (queryParams[vinKey] || '') as string
                : sessionStorage.getItem(sessionStoragePrefix + vinKey) || '',
            backofficeStatus: this.props.location.search && this.props.location.search.length > 0
                ? (queryParams[backofficeStatusKey] || '') as string
                : sessionStorage.getItem(sessionStoragePrefix + backofficeStatusKey) || ''
        };
        let urlQuery = qs.stringify(params);
        if (urlQuery != this.props.location.search) {
            this.props.history.push({search: urlQuery});
        }
        return params;
    }


    componentDidMount(): void {
        this.ignoreComponentDidUpdateOnce = true;
        this.loadProjects();
    }

    componentDidUpdate(prevProps: Readonly<IExternalProjectsProps>, prevState: Readonly<IExternalProjectsState>, snapshot?: any): void {
        if (!this.ignoreComponentDidUpdateOnce && prevProps.location.search !== this.props.location.search ) {
            const queryParams = qs.parse(this.props.location.search);
            const today = moment(new Date()).format(DATE_FORMAT);

            this.ignoreComponentDidUpdateOnce = true;
            this.setState({
                params: {
                    dateFrom: (queryParams[dateFromKey] || today) as string,
                    dateTo: (queryParams[dateToKey] || today) as string,
                    vin: (queryParams[vinKey] || '') as string,
                    backofficeStatus: (queryParams[backofficeStatusKey] || '') as string,
                }
            }, () => this.loadProjects());
        }

        this.ignoreComponentDidUpdateOnce = false;
    }

    public render() {
        if (this.state.isFetching) {
            return <label>loading...</label>
        }

        const options = {
            onRowClick: (row, columnIndex, rowIndex, e) => this.loadSelectedProject(row.processId)
        };
        const trStyle = (row, rowIndex) => {
            return {cursor: 'pointer'};
        }

        return (
            <div className="external-projects-container">
                <div className="external-projects-toolbar">
                    {strings.externalProjects.dateFrom}: &nbsp;
                    <DayPickerInput format=""
                                    value={this.state.params.dateFrom}
                                    parseDate={this.parseDate}
                                    dayPickerProps={{
                                        selectedDays: this.parseDate(this.state.params.dateFrom),
                                        locale: strings.getLanguage(),
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    onDayChange={day =>
                                        this.setState({params: {...this.state.params, dateFrom: moment(day).format(DATE_FORMAT)}})}/>

                    {strings.externalProjects.dateTo}: &nbsp;
                    <DayPickerInput format=""
                                    value={this.state.params.dateTo}
                                    dayPickerProps={{
                                        selectedDays: this.parseDate(this.state.params.dateTo),
                                        locale: strings.getLanguage(),
                                        localeUtils: MomentLocaleUtils,
                                    }}
                                    onDayChange={day =>
                                        this.setState({params: {...this.state.params, dateTo: moment(day).format(DATE_FORMAT)}})}/>
                    {strings.externalProjects.vin}: &nbsp;
                    <input type="text" value={this.state.params.vin} onChange={(event) => this.setState({params: {...this.state.params, vin: event.target.value}})}/>
                    &nbsp;

                    {this.props.user.roles.indexOf(CAN_MANAGE_PROCESSES) >= 0 &&
                     <span>
                        {strings.externalProjects.backofficeStatus.title} &nbsp;
                        <select value={this.state.params.backofficeStatus}
                                onChange={(event) => this.setState({ params: {...this.state.params, backofficeStatus: event.target.value }})}>
                            {Object.keys(BackofficeStatus).map(key => (
                                <option key={key} value={BackofficeStatus[key].id}>
                                    {BackofficeStatus[key].label}
                                </option>
                            ))}
                        </select>
                         &nbsp;
                     </span>
                    }

                    <Button variant="secondary" onClick={() => this.onParamsChanged()}>{strings.externalProjects.apply}</Button>
                    <span className="space"/>
                    <Button variant="secondary" title={strings.externalProjects.reloadData}
                            onClick={() => this.onReloadData()}><i className="fas fa-sync"/></Button>
                </div>
                <BootstrapTable data={this.state.data}
                                trStyle={trStyle}
                                bodyContainerClass="external-projects-table"
                                striped hover bordered={false} version='4'
                                options={options}>
                    <TableHeaderColumn isKey dataField='processId'>{strings.externalProjects.processId}</TableHeaderColumn>
                    <TableHeaderColumn dataField='boxId'>{strings.externalProjects.boxId}</TableHeaderColumn>
                    <TableHeaderColumn dataField='internalId'>{strings.externalProjects.internalId}</TableHeaderColumn>
                    <TableHeaderColumn dataField='vin'>{strings.externalProjects.vin}</TableHeaderColumn>
                    <TableHeaderColumn dataField='dvsCreationTime'>{strings.externalProjects.dvsCreationTime}</TableHeaderColumn>
                    <TableHeaderColumn dataField='processCreationTime'>{strings.externalProjects.processCreationTime}</TableHeaderColumn>
                    <TableHeaderColumn dataField='processCompletionTime'>{strings.externalProjects.processCompletionTime}</TableHeaderColumn>
                    <TableHeaderColumn dataField='hardwareType'>{strings.externalProjects.hardwareType}</TableHeaderColumn>
                </BootstrapTable>
            </div>
        );
    }

    private onParamsChanged() {
        const setOrClearSession = (key, value) => {
            if (value) {
                sessionStorage.setItem(key, value);
            } else {
                sessionStorage.removeItem(key);
            }
        };
        const params = this.state.params;
        setOrClearSession(sessionStoragePrefix + dateFromKey, params.dateFrom);
        setOrClearSession(sessionStoragePrefix + dateToKey, params.dateTo);
        setOrClearSession(sessionStoragePrefix + vinKey, params.vin);
        setOrClearSession(sessionStoragePrefix + backofficeStatusKey, params.backofficeStatus);
        this.props.history.push({search: qs.stringify(this.state.params)});
    }

    private onReloadData() {
        const key = this.hash(this.state.params);
        sscache.remove(key);
        this.loadProjects();
    }

    private parseDate(date: string, format?: string, locale?: string): Date {
        return moment(date, DATE_FORMAT, locale).toDate();
    }

    private loadProjects() {
        const key = this.hash(this.state.params);
        this.setState({
            isFetching: true
        });

        const cachedValue = sscache.get(key);
        if (cachedValue) {
            try {
                const data = JSON.parse(cachedValue);
                this.setState({
                    data,
                    isFetching: false
                });
                return;
            } catch (e) {
                console.error(e);
                sscache.remove(key);
            }
        }
        const params = this.state.params;
        const from = moment(params.dateFrom).format(DATE_FORMAT);
        const to = moment(params.dateTo).format(DATE_FORMAT);
        this.externalProjectService
            .loadProjects(from, to, params.vin || '', params.backofficeStatus || '')
            .then(result => {
                sscache.set(key, JSON.stringify(result), cacheExpiryMinutes);
                this.setState({
                    data: result,
                    isFetching: false
                });
            })
            .catch(e => {
                if (TomcomBackendService.isSessionExpiredError(e)) {
                    this.props.actions.sessionExpired();
                } else {
                    this.setState({
                        isFetching: false,
                        data: []
                    });
                }
            });

    }

    private hash(val: IExternalProjectsUrlParams): string {
        return _.values(val).join("_");
    }

    private loadSelectedProject = async (id: string) => {
        let project = await this.props.actions.loadProject(id);
        this.props.history.push(`/projects/${project.id}/edit`);
    }
}
