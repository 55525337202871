import './carmanagerLinks.scss'
import React from "react";
import { CarmanagerService } from "./carmanagerService";
import { IUser } from "../login/user";
import { IApplicationState } from "../../models/applicationState";
import { connect } from "react-redux";
import { CarmanagerBewertung, CarmanagerBewertungStatus } from "./carmanagerBewertung";
import axios, { CancelTokenSource } from "axios";
import { strings } from "../../common/strings";
import { CarmanagerDocument, CarmanagerDocumentType } from "./carmanagerDocument";
import * as FileSaver from "file-saver";
import { CarmanagerDocumentPreview } from "./carmanagerDocumentPreview";
import { toast } from "react-toastify";

export interface ICarmanagerLinksProps {
    user?: IUser,
    vin: string
}

export interface ICarmanagerLinksState {
    bewertung?: CarmanagerBewertung
}

function mapStateToProps(state: IApplicationState) {
    return {
        user: state.currentUser
    };
}

@connect(mapStateToProps)
export class CarmanagerLinks extends React.Component<ICarmanagerLinksProps, ICarmanagerLinksState> {

    private cancelToken: CancelTokenSource = axios.CancelToken.source();

    constructor(props: ICarmanagerLinksProps, context: any) {
        super(props, context);

        this.state = {};
        this.carmanagerService = new CarmanagerService(this.props.user)
    }

    private carmanagerService;

    componentDidMount(): void {
        const vin = !process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? "WAUZZZF85JN012208" : this.props.vin;
        this.carmanagerService
            .loadCarmanagerInfo(this.cancelToken.token, vin)
            .then((result) => {
                this.setState({
                    bewertung: result
                })
            });

    }

    componentWillUnmount() {
        this.cancelToken.cancel("Component will unmount, cancelling request");
    }

    public render() {

        let layout = <div className="asset-loading">
            <div className="asset-loading-spinner">
                <i className="fas fa-circle-notch fa-spin"/>
                &nbsp;{strings.editorPage.carmanager.loading}
            </div>
        </div>;

        if (this.state.bewertung) {
            switch (this.state.bewertung.status) {
                case CarmanagerBewertungStatus.VEHICLE_NOT_FOUND:
                    layout = <span>{strings.editorPage.carmanager.vehicleNotFound}</span>;
                    break;
                case CarmanagerBewertungStatus.EVALUATION_NOT_FOUND:
                    layout = <span>{strings.editorPage.carmanager.evaluationNotFound}</span>;
                    break;
                case CarmanagerBewertungStatus.FOUND:
                    layout = this.generateCarmanagerLinks(this.state.bewertung);
                    break;
                case CarmanagerBewertungStatus.UNKNOWN:
                default:
                    layout = <span>{strings.editorPage.carmanager.unknownStatus}</span>;
            }
        }

        return (
            <div className='carmanager-links-container'>
                {layout}
            </div>
        );
    }

    private generateCarmanagerLinks(b: CarmanagerBewertung) {
        const pdf = b.documents.find(val => val.type === CarmanagerDocumentType.PDF);
        const ansichtBilder = b.documents.filter(value => value.type === CarmanagerDocumentType.EXTERIOR_FOTO);
        const schadenBilder = b.documents.filter(value => value.type === CarmanagerDocumentType.DAMAGE_FOTO);

        return <div className='carmanager-links'>
            {
                pdf &&
                <React.Fragment>
                    {/*<div>{strings.editorPage.carmanager.evaluation}</div>*/}
                    <CarmanagerDocumentPreview document={pdf}
                                               onClick={(doc) => this.downloadDocument(doc)}/>
                </React.Fragment>
            }
            {
                ansichtBilder &&
                <React.Fragment>
                    <hr/>
                    {/*<div>{strings.editorPage.carmanager.exteriorFotos}</div>*/}
                    {ansichtBilder.map(doc =>
                        <CarmanagerDocumentPreview document={doc} key={doc.id}
                                                   onClick={(doc) => this.downloadDocument(doc)}/>)}
                </React.Fragment>
            }
            {
                schadenBilder &&
                <React.Fragment>
                    <hr/>
                    {/*<div>{strings.editorPage.carmanager.damageFotos}</div>*/}
                    {schadenBilder.map(doc =>
                        <CarmanagerDocumentPreview document={doc} key={doc.id}
                                                   onClick={(doc) => this.downloadDocument(doc)}/>)}
                </React.Fragment>
            }
        </div>;
    }

    private downloadDocument(doc: CarmanagerDocument) {
        toast.info(strings.editorPage.carmanager.downloadingDocument);
        this.carmanagerService
            .loadDocument(doc)
            .then(data => {
                toast.dismiss();
                switch (doc.type) {
                    case CarmanagerDocumentType.PDF:
                        const blob = new Blob([data], {type: 'application/pdf'});
                        FileSaver.saveAs(blob, `${doc.title}.pdf`);
                        break;
                    default:
                        let win = window.open('', '_blank');
                        const base64 = new Buffer(data, 'binary').toString('base64');
                        const html = `<html>
                          <head>
                            <title>${doc.title}</title>
                          </head>
                          <body>
                            <img src="data:image/jpeg;base64,${base64}" />
                          </body>
                        </html>`;
                        win.document.body.innerHTML = html;
                        break;
                }
            });
    }
}
