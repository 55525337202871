export interface IUser {
    id?: number;
    username?: string;
    password?: string;
    authToken?: string;
    roles?: string[];
    groups?: string[];
}

export const CAN_MANAGE_PROCESSES = "ROLE_MANAGE_FINISHED_PROCESSES";
