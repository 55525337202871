import React from "react";
import { connect } from 'react-redux';
import axios, { CancelTokenSource } from "axios";
import { CarmanagerDocument } from "./carmanagerDocument";
import { IUser } from "../login/user";
import { IApplicationState } from "../../models/applicationState";
import { CarmanagerService } from "./carmanagerService";

interface IImageAssetProps {
    document: CarmanagerDocument;
    currentUser?: IUser;
    onClick?: (doc: CarmanagerDocument) => void;
}

interface IImageAssetState {
    loaded: boolean;
    imageSrc: any;
}

function mapStateToProps(state: IApplicationState) {
    return {
        currentProject: state.currentProject,
        currentUser: state.currentUser
    };
}

@connect(mapStateToProps, null)
export class CarmanagerDocumentPreview extends React.Component<IImageAssetProps, IImageAssetState> {

    private cancelToken: CancelTokenSource = axios.CancelToken.source();
    private carmanagerService: CarmanagerService;

    constructor(props: IImageAssetProps, context: any) {
        super(props, context);

        this.carmanagerService = new CarmanagerService(props.currentUser);

        this.state = {
            loaded: false,
            imageSrc: undefined
        };
    }


    componentDidMount(): void {
        this.loadPreview();
    }


    componentDidUpdate(prevProps: Readonly<IImageAssetProps>, prevState: Readonly<IImageAssetState>, snapshot?: any): void {
        if (prevProps.document !== this.props.document) {
            this.loadPreview();
        }
    }

    componentWillUnmount() {
        this.cancelToken.cancel("Component will unmount, cancelling request");
    }

    private loadPreview() {
        this.carmanagerService
        .loadDocumentThumbnailAsBase64(this.cancelToken.token, this.props.document)
        .then(base64Content => {
            this.setState({
                loaded: true,
                imageSrc: `data:image/jpeg;base64,${base64Content}`
            })
        })
        .catch((error) => {
            if (!axios.isCancel(error)) {
                this.setState({
                    loaded: true,
                    imageSrc: ''
                })
            }
        })
    }



    public render() {
        if (this.state.loaded) {
            return (
                <React.Fragment>
                    <img src={this.state.imageSrc} className="carmanager-link"
                         onClick={(e) => this.props.onClick(this.props.document)}
                         crossOrigin="anonymous"/>
                    <span className="carmanager-link-description">{this.props.document.title}</span>
                </React.Fragment>
            )
        } else {
            return <div/>
        }
    }
}
