import React from "react";
import { appInfo } from "../../../common/appInfo";
import "./statusBar.scss";
import { IApplicationState} from "../../../models/applicationState";
import { connect } from "react-redux";
import ILoginActions, * as loginActions from "../../../tomcom/login/loginActions";
import { bindActionCreators } from "redux";
import { strings } from "../../../common/strings";
import { IUser } from "../../../tomcom/login/user";

export interface IStatusBarProps {
    actions?: ILoginActions;
    currentUser?: IUser;
    children?: any;
}

function mapStateToProps(state: IApplicationState) {
    return {
        currentUser: state.currentUser
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(loginActions, dispatch),
    };
}

@connect(mapStateToProps, mapDispatchToProps)
export class StatusBar extends React.Component<IStatusBarProps> {
    public render() {

        return (
            <div className="status-bar">
                {
                    this.props.currentUser &&
                    <a href="#" title={strings.login.logoutHint} onClick={() => this.logout()}>
                        <i className="fas fa-sign-out-alt"/>
                        &nbsp;
                        {this.props.currentUser.username}
                        {this.props.currentUser.groups && this.props.currentUser.groups.length>0 &&
                        <span>({this.props.currentUser.groups.join(", ")})</span>
                        }
                    </a>
                }
                <div className="status-bar-main">{this.props.children}</div>
                <div className="status-bar-version">
                    <ul>
                        <li>
                            <i className="fas fa-code-branch"/>
                            <span>{appInfo.version}</span>
                        </li>
                    </ul>
                </div>
            </div>
        );
    }

    private logout(): void {
        this.props.actions.logout();
    }
}
