"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SelectionMode = void 0;
/**
 * Enum to define current selectio mode
 */

var SelectionMode;

(function (SelectionMode) {
  SelectionMode[SelectionMode["NONE"] = 0] = "NONE";
  SelectionMode[SelectionMode["DRAG"] = 1] = "DRAG";
  SelectionMode[SelectionMode["POINT"] = 2] = "POINT";
  SelectionMode[SelectionMode["RECT"] = 3] = "RECT";
  SelectionMode[SelectionMode["COPYRECT"] = 4] = "COPYRECT";
  SelectionMode[SelectionMode["POLYLINE"] = 5] = "POLYLINE";
  SelectionMode[SelectionMode["POLYGON"] = 6] = "POLYGON";
})(SelectionMode = exports.SelectionMode || (exports.SelectionMode = {}));