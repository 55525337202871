/**
 * User: pavel
 * Date: 23.08.19
 * Time: 17:20
 */

export enum CarmanagerDocumentType {
    PDF = "PDF",
    EXTERIOR_FOTO = "EXTERIOR_FOTO",
    DAMAGE_FOTO = "DAMAGE_FOTO",
    UNKNOWN = "UNKNOWN"
}

export class CarmanagerDocument {

    public id: string;
    public type: CarmanagerDocumentType;
    public title: string;
    public order: number;

}
