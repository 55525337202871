import { ActionTypes } from "../../redux/actions/actionTypes";
import { AnyAction } from "../../redux/actions/actionCreators";
import { IUser } from "./user";

/**
 *
 */
export const reducer = (state: IUser = null, action: AnyAction) => {
    switch (action.type) {
        case ActionTypes.LOGIN_SUCCESS:
            return {...action.payload};
        case ActionTypes.LOGOUT:
            return null;
        default:
            return state;
    }
};
