/**
 * User: pavel
 * Date: 21.06.19
 * Time: 10:38
 */
import { IStorageProvider } from "./storageProviderFactory";
import { AssetState, IAsset, StorageType, AssetType } from "../../models/applicationState";
import axios from 'axios';
import { IUser } from "../../tomcom/login/user";

export class TomcomCloudStorage implements IStorageProvider {

    public static NAME = "tomcomCloudStorage";

    private readonly http;

    public storageType: StorageType = StorageType.Cloud;

    constructor() {
        const user: IUser = JSON.parse(localStorage.getItem("currentUser"));
        this.http = axios.create({
            headers: {
                'x-auth-token': user.authToken
            }
        })
    }

    createContainer(folderPath: string): Promise<void> {
        return undefined;
    }

    deleteContainer(folderPath: string): Promise<void> {
        return undefined;
    }

    deleteFile(filePath: string): Promise<void> {
        return undefined;
    }

    getAssets(containerName?: string): Promise<IAsset[]> {
        console.debug("getAssets from container " + containerName);
        return Promise.resolve([]);
    }

    listContainers(folderPath?: string): Promise<string[]> {
        console.debug("listContainers: " + folderPath);
        return undefined;
    }

    async listFiles(folderPath?: string, ext?: string): Promise<string[]> {
        console.debug("listFiles: " + folderPath + "." + ext);
        return Promise.resolve([]);
    }

    readBinary(filePath: string): Promise<Buffer> {
        console.debug("readBinary: " + filePath);
        return undefined;
    }

    async readText(filePath: string): Promise<string> {
        console.warn("readText: " + filePath);
        const result = await this.http.get("/projects/textFiles/" + encodeURI(filePath));
        return JSON.stringify(result.data);
    }

    writeBinary(filePath: string, contents: Buffer): Promise<void> {
        console.debug("writeBinary: " + filePath);
        return Promise.resolve();
    }

    writeText(filePath: string, contents: string): Promise<void> {
        return Promise.resolve();
    }

}
