import { CancelToken } from "axios";
import { TomcomBackendService } from "../tomcomBackendService";
import { IUser } from "../login/user";
import { CarmanagerBewertung } from "./carmanagerBewertung";
import { CarmanagerDocument, CarmanagerDocumentType } from "./carmanagerDocument";

export class CarmanagerService extends TomcomBackendService {

    constructor(user: IUser) {
        super(user);
    }

    public async loadCarmanagerInfo(cancelToken: CancelToken, vin: string): Promise<CarmanagerBewertung> {
        const response = await this.http.get(`/carmanager/bewertung/${vin}`, {
            cancelToken: cancelToken
        });
        return response.data;
    }

    public async loadDocument(doc: CarmanagerDocument): Promise<any> {
        const response = await this.http.get(`/carmanager/document/${doc.id}`, {
            responseType: 'arraybuffer'
        });
        return response.data;
    }

    public async loadDocumentThumbnailAsBase64(cancelToken: CancelToken, doc: CarmanagerDocument): Promise<string> {
        const response = await this.http.get(`/carmanager/document/${doc.id}?thumbnail=true`, {
            responseType: 'arraybuffer',
            cancelToken: cancelToken,
            transformResponse: [function (data) {
                return new Buffer(data, 'binary').toString('base64');
            }],
        });
        return response.data;
    }



}
