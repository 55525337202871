"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ChangeEventType = void 0;
/**
 * Defines supported events types for regions.
 */

var ChangeEventType;

(function (ChangeEventType) {
  ChangeEventType[ChangeEventType["MOVEEND"] = 0] = "MOVEEND";
  ChangeEventType[ChangeEventType["MOVING"] = 1] = "MOVING";
  ChangeEventType[ChangeEventType["MOVEBEGIN"] = 2] = "MOVEBEGIN";
  ChangeEventType[ChangeEventType["SELECTIONTOGGLE"] = 3] = "SELECTIONTOGGLE";
})(ChangeEventType = exports.ChangeEventType || (exports.ChangeEventType = {}));