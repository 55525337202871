import React from "react";
import { IAssetProps } from "./assetPreview";
import { IApplicationState, IProject } from "../../../../models/applicationState";
import { connect } from 'react-redux';
import { TomcomCloudStorage } from "../../../../providers/storage/tomcomCloudStorage";
import { ExternalProjectsService } from "../../../../tomcom/externalProjects/externalProjectsService";
import { IUser } from "../../../../tomcom/login/user";
import axios, { CancelTokenSource } from "axios";

interface IImageAssetProps extends IAssetProps {
    currentProject?: IProject;
    currentUser?: IUser;
    loadThumbnail?: boolean;
}

interface IImageAssetState {
    loaded: boolean;
    imageSrc: any;
}


function mapStateToProps(state: IApplicationState) {
    return {
        currentProject: state.currentProject,
        currentUser: state.currentUser
    };
}

/**
 * ImageAsset component used to render all image assets
 */
@connect(mapStateToProps, null)
export class ImageAsset extends React.Component<IImageAssetProps, IImageAssetState> {

    private image: React.RefObject<HTMLImageElement> = React.createRef();
    private cancelToken: CancelTokenSource = axios.CancelToken.source();


    constructor(props: IImageAssetProps, context: any) {
        super(props, context);

        this.state = {
            loaded: false,
            imageSrc: props.asset.path
        };
    }


    componentDidMount(): void {
        this.loadImageIfNeeded();
    }


    componentDidUpdate(prevProps: Readonly<IImageAssetProps>, prevState: Readonly<IImageAssetState>, snapshot?: any): void {
        if (prevProps.asset.id !== this.props.asset.id) {
            this.loadImageIfNeeded();
        }
    }

    componentWillUnmount() {
        this.cancelToken.cancel("Component will unmount, cancelling request");
    }

    private loadImageIfNeeded() {
        if (false) {
            this.setState({
                loaded: true,
                imageSrc: "https://images.pexels.com/photos/257840/pexels-photo-257840.jpeg"
            });
            return;
        }
        //Wenn Bilder nicht aus TomcomCloud kommen werden die normal via src geladen sonst via service wegen Auth
        const loadImagesViaService = this.props.currentProject &&
            this.props.currentProject.sourceConnection.providerType === TomcomCloudStorage.NAME;
        this.setState({
            loaded: !loadImagesViaService
        });
        if (loadImagesViaService) {
            this.loadImageFromBackend();
        }
    }

    private loadImageFromBackend() {
        new ExternalProjectsService(this.props.currentUser)
        .loadImage(this.cancelToken.token, this.props.currentProject.id, this.props.asset.id, this.props.loadThumbnail)
        .then(base64Content => {
            this.setState({
                loaded: true,
                imageSrc: `data:image/jpeg;base64,${base64Content}`
            })
        })
        .catch((error) => {
            if (!axios.isCancel(error)) {
                this.setState({
                    loaded: true,
                    imageSrc: ''
                })
            }
        })
    }

    public render() {
        if (this.state.loaded) {
            return (
                <img ref={this.image}
                     src={this.state.imageSrc}
                     onLoad={this.onLoad}
                     onError={this.props.onError}
                     crossOrigin="anonymous"/>);
        } else {
            return <div/>
        }
    }

    private onLoad = () => {
        if (this.props.onLoaded) {
            this.props.onLoaded(this.image.current);
        }
        if (this.props.onActivated) {
            this.props.onActivated(this.image.current);
        }
        if (this.props.onDeactivated) {
            this.props.onDeactivated(this.image.current);
        }
    }


}
