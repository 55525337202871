import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import ActiveLearningPage from "../pages/activeLearning/activeLearningPage";
import AppSettingsPage from "../pages/appSettings/appSettingsPage";
import EditorPage from "../pages/editorPage/editorPage";
import ExportPage from "../pages/export/exportPage";
import ProjectSettingsPage from "../pages/projectSettings/projectSettingsPage";
import NotFound from "../pages/notFoundPage";
import AuthenticatedRoute from "../../../tomcom/login/authenticatedRoute";
import ExternalProjectsPage from "../../../tomcom/externalProjects/externalProjectsPage";
import LoginPage from "../../../tomcom/login/loginPage";

/**
 * @name - Main Content Router
 * @description - Controls main content pane based on route
 */
export default function MainContentRouter() {
    return (
        <div className="app-content text-light">
            <Switch>

                <Route path="/" exact render={props => <Redirect
                    to={{
                        pathname: "/ibm",
                        state: {from: props.location}
                    }}
                />}/>

                <Route path="/login" component={LoginPage}/>

                <AuthenticatedRoute path="/ibm" exact component={ExternalProjectsPage}/>

                {/*<AuthenticatedRoute path="/" exact component={HomePage} />*/}
                <AuthenticatedRoute path="/settings" component={AppSettingsPage}/>
                {/*<AuthenticatedRoute path="/connections/:connectionId" component={ConnectionPage} />*/}
                {/*<AuthenticatedRoute path="/connections" exact component={ConnectionPage} />*/}
                <AuthenticatedRoute path="/projects/:projectId/edit" component={EditorPage}/>
                {/*<AuthenticatedRoute path="/projects/create" component={ProjectSettingsPage}/>*/}
                {/*<AuthenticatedRoute path="/projects/:projectId/settings" component={ProjectSettingsPage}/>*/}
                {/*<AuthenticatedRoute path="/projects/:projectId/export" component={ExportPage}/>*/}
                {/*<AuthenticatedRoute path="/projects/:projectId/active-learning" component={ActiveLearningPage}/>*/}


                <Route component={NotFound}/>
            </Switch>
        </div>
    );
}
