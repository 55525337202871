import { IExternalProject } from "./externalProject";
import { IApplicationState, IProject, sessionExpiredError } from "../../models/applicationState";
import { Action, Dispatch } from "redux";
import { ExternalProjectsService } from "./externalProjectsService";
import { ActionTypes } from "../../redux/actions/actionTypes";
import { createPayloadAction, IPayloadAction } from "../../redux/actions/actionCreators";
import { loadProject as loadAppProject } from '../../redux/actions/projectActions';
import { TomcomBackendService } from "../tomcomBackendService";
import { showErrorAction } from "../../redux/actions/appErrorActions";
import { logoutAction } from "../login/loginActions";

export default interface IExternalProjectActions {
    loadProjects(): Promise<IExternalProject[]>;
    loadProject(id: string): Promise<IProject>;
    sessionExpired(): Promise<any>;
}

export function sessionExpired(): (dispatch: Dispatch, getState: () => IApplicationState) => Promise<IExternalProject[]> {
    return async (dispatch: Dispatch, getState: () => IApplicationState) => {
        dispatch(showErrorAction(sessionExpiredError));
        dispatch(logoutAction());
        return Promise.resolve([]);
    };
}

export function loadProject(id: string): (dispatch: Dispatch, getState: () => IApplicationState) => Promise<IProject> {
    return async (dispatch: Dispatch, getState: () => IApplicationState) => {

        dispatch({type: ActionTypes.EXTERNAL_PROJECTS_LOAD_LIST_PROGRESS});

        const projectService = new ExternalProjectsService(getState().currentUser);

        try {
            let project = await projectService.loadProject(id);
            await loadAppProject(project)(dispatch, getState);
            dispatch(loadExternalProjectSuccessAction(project));
            return project;
        } catch (e) {
            if (TomcomBackendService.isSessionExpiredError(e)) {
                dispatch(showErrorAction(sessionExpiredError));
                dispatch(logoutAction());
            }
            return Promise.resolve(null);
        }
    };
}

export interface ILoadExternalProjectsListSuccessAction extends IPayloadAction<string, IExternalProject[]> {
    type: ActionTypes.EXTERNAL_PROJECTS_LOAD_SUCCESS;
}

export interface ILoadExternalProjectSuccessAction extends IPayloadAction<string, IProject> {
    type: ActionTypes.EXTERNAL_PROJECTS_LOAD_PROJECT_SUCCESS;
}

export interface ILoadExternalProjectsProgressAction extends Action<string> {
    type: ActionTypes.EXTERNAL_PROJECTS_LOAD_LIST_PROGRESS;
}

export const loadExternalProjectsListSuccessAction = createPayloadAction<ILoadExternalProjectsListSuccessAction>(ActionTypes.EXTERNAL_PROJECTS_LOAD_SUCCESS);

export const loadExternalProjectSuccessAction = createPayloadAction<ILoadExternalProjectSuccessAction>(ActionTypes.EXTERNAL_PROJECTS_LOAD_PROJECT_SUCCESS);

