type RunMode = "staging" | "production" | "dev";
export class Env {

    public static get() {
        return process.env.NODE_ENV;
    }

    public static backendUrl() {
        return process.env.REACT_APP_BACKEND_URL;
    }

    public static runMode(): RunMode {
        return process.env.REACT_APP_MODE as RunMode;
    }
}
