/**
 * User: pavel
 * Date: 13.06.19
 * Time: 16:48
 */
import { Redirect, RouteProps, Route } from "react-router";
import React, { Component } from "react";
import { connect } from 'react-redux'
import { IApplicationState} from "../../models/applicationState";
import { IUser } from "./user";

interface IAuthenticatedRouteProps extends RouteProps {
    currentUser?: IUser
}

//das ist eine High-Order-Function um High-Order-Components zu erzeugen
//renderet Component falls authentifiziert oder Redirect zu Login
const withAuth = <P extends object>(Component: React.ComponentType<P>) =>
    class WithAuth extends React.Component<P & IAuthenticatedRouteProps> {
        render() {
            return (
                this.props.currentUser
                    ? <Component {...this.props as P} />
                    : <Redirect
                        to={{
                            pathname: "/login",
                            state: {from: this.props.location}
                        }}/>
            );
        }
    };

function mapStateToProps(state: IApplicationState) {
    return {
        currentUser: state.currentUser
    };
}

//Route-Componente mit injektiertem App-State und zwar dem Benutzer
const AuthenticatedRoute = connect(mapStateToProps)(withAuth(Route));
export default AuthenticatedRoute;
