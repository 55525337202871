import {strings} from "../../common/strings";

/**
 * User: pavel
 * Date: 13.07.20
 * Time: 17:55
 */
export const BackofficeStatus = {
    all: {
        id: "all",
        label: strings.externalProjects.backofficeStatus.all
    },
    finished: {
        id: "backOfficeFinished",
        label: strings.externalProjects.backofficeStatus.finished
    },
    unfinished: {
        id: "backOfficeUnfinished",
        label: strings.externalProjects.backofficeStatus.unfinished
    },
}
