import _ from "lodash";
import { ExportProvider } from "./exportProvider";
import { IExportProviderOptions, IProject } from "../../models/applicationState";
import Guard from "../../common/guard";
import { constants } from "../../common/constants";
import HtmlFileReader from "../../common/htmlFileReader";
import { TomcomCloudStorage } from "../storage/tomcomCloudStorage";
import { ExternalProjectsService } from "../../tomcom/externalProjects/externalProjectsService";

/**
 * VoTT Json Export Provider options
 */
export interface IVottJsonExportProviderOptions extends IExportProviderOptions {
    /** Whether or not to include binary assets in target connection */
    includeImages: boolean;
}

/**
 * @name - Vott Json Export Provider
 * @description - Exports a project into a single JSON file that include all configured assets
 */
export class VottJsonExportProvider extends ExportProvider<IVottJsonExportProviderOptions> {
    constructor(project: IProject, options: IVottJsonExportProviderOptions) {
        super(project, options);
        Guard.null(options);
    }

    /**
     * Export project to VoTT JSON format
     */
    public async export(): Promise<void> {
        const results = await this.getAssetsForExport();

        if (this.options.includeImages) {
            await results.forEachAsync(async (assetMetadata) => {
                const arrayBuffer = await HtmlFileReader.getAssetArray(assetMetadata.asset);
                const assetFilePath = `vott-json-export/${assetMetadata.asset.name}`;
                await this.storageProvider.writeBinary(assetFilePath, Buffer.from(arrayBuffer));
            });
        }

        const exportObject = {...this.project};
        exportObject.assets = _.keyBy(results, (assetMetadata) => assetMetadata.asset.id) as any;

        // We don't need these fields in the export JSON
        delete exportObject.sourceConnection;
        delete exportObject.targetConnection;
        delete exportObject.exportFormat;

        const fileName = `vott-json-export/${this.project.name.replace(/\s/g, "-")}${constants.exportFileExtension}`;

        const uploadViaTomcomBackend = this.project.targetConnection.providerType === TomcomCloudStorage.NAME;
        if (uploadViaTomcomBackend) {
            //Ein Workaround um Benutzer zu ermitteln. Habe keine Lust die Scheiße umzubauen
            const exportService = new ExternalProjectsService(JSON.parse(localStorage.getItem("currentUser")));
            _.values(exportObject.assets)
            .forEachAsync(async (metadata) => await exportService.uploadImageMetadata(this.project.id, metadata))
        } else {
            await this.storageProvider.writeText(fileName, JSON.stringify(exportObject, null, 4));
        }
    }
}
