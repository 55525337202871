import { IApplicationState } from "../../models/applicationState";
import { TomcomCloudStorage } from "../../providers/storage/tomcomCloudStorage";
import shortid from 'shortid'

export const tomcomConnection = {
    id: shortid.generate(),
    name: 'Tomcom Cloud',
    description: 'Tomcom Backend',
    providerType: TomcomCloudStorage.NAME,
    providerOptions: {}
};

/**
 * Initial state of application
 * @member appSettings - Application settings
 * @member connections - Connections
 * @member recentProjects - Recent projects
 * @member currentProject - Current project
 */
const initialState: IApplicationState = {
    appSettings: {
        devToolsEnabled: false,
        securityTokens: [],
    },
    connections: [tomcomConnection],
    currentProject: null,
    appError: null,
    currentUser: null,
};

/**
 * Instance of initial application state
 */
export default initialState;
