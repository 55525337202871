/**
 * User: pavel
 * Date: 18.06.19
 * Time: 16:29
 */
import { Env } from "./common/environment";
import axios from "axios";

export default function configureHttpLayer() {

    axios.defaults.baseURL = Env.backendUrl();
    console.info(`Will use backend ${Env.backendUrl()}`);
}
