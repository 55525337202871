import _ from "lodash";
import React, {KeyboardEvent as ReactKeyboardEvent, RefObject} from "react";
import {connect} from "react-redux";
import {RouteComponentProps} from "react-router-dom";
import SplitPane from "react-split-pane";
import {bindActionCreators} from "redux";
import {SelectionMode} from "vott-ct/lib/js/CanvasTools/Interface/ISelectorSettings";
import HtmlFileReader from "../../../../common/htmlFileReader";
import {strings} from "../../../../common/strings";
import {
    AppError,
    AssetState,
    AssetType,
    EditorMode,
    ErrorCode,
    IAdditionalPageSettings,
    IApplicationState,
    IAppSettings,
    IAsset,
    IAssetMetadata,
    IProject,
    IRegion,
    ISize,
    ITag,
    SORT_ASSETS_BY_CAMERA_AND_ORDER,
} from "../../../../models/applicationState";
import {IToolbarItemRegistration, ToolbarItemFactory} from "../../../../providers/toolbar/toolbarItemFactory";
import IApplicationActions, * as applicationActions from "../../../../redux/actions/applicationActions";
import IProjectActions, * as projectActions from "../../../../redux/actions/projectActions";
import * as externalProjectActions from "../../../../tomcom/externalProjects/externalProjectActions";
import IExternalProjectActions from "../../../../tomcom/externalProjects/externalProjectActions";
import {ToolbarItemName} from "../../../../registerToolbar";
import {AssetService} from "../../../../services/assetService";
import {AssetPreview} from "../../common/assetPreview/assetPreview";
import {KeyboardBinding} from "../../common/keyboardBinding/keyboardBinding";
import {KeyEventType} from "../../common/keyboardManager/keyboardManager";
import {TagInput} from "../../common/tagInput/tagInput";
import {ToolbarItem} from "../../toolbar/toolbarItem";
import Canvas from "./canvas";
import CanvasHelpers from "./canvasHelpers";
import "./editorPage.scss";
import EditorSideBar from "./editorSideBar";
import {EditorToolbar} from "./editorToolbar";
import Alert from "../../common/alert/alert";
import Confirm from "../../common/confirm/confirm";
import {ActiveLearningService} from "../../../../services/activeLearningService";
import {toast} from "react-toastify";
import {CarmanagerLinks} from "../../../../tomcom/carmanager/carmanagerLinks";
import IAppErrorActions, * as appErrorActions from "../../../../redux/actions/appErrorActions";
import {IUser} from "../../../../tomcom/login/user";
import {ExternalProjectsService} from "../../../../tomcom/externalProjects/externalProjectsService";
import sscache from "session-storage-cache";
import shortid from 'shortid'

/**
 * Properties for Editor Page
 * @member project - Project being edited
 * @member actions - Project actions
 * @member applicationActions - Application setting actions
 */
export interface IEditorPageProps extends RouteComponentProps, React.Props<EditorPage> {
    project: IProject;
    appSettings: IAppSettings;
    currentUser?: IUser;
    actions: IProjectActions;
    applicationActions: IApplicationActions;
    externalProjectActions?: IExternalProjectActions;
    appErrorActions?: IAppErrorActions
}

/**
 * State for Editor Page
 */
export interface IEditorPageState {
    /** Array of assets in project */
    assets: IAsset[];
    /** The editor mode to set for canvas tools */
    editorMode: EditorMode;
    /** The selection mode to set for canvas tools */
    selectionMode: SelectionMode;
    /** The selected asset for the primary editing experience */
    selectedAsset?: IAssetMetadata;
    /** Currently selected region on current asset */
    selectedRegions?: IRegion[];
    /** The child assets used for nest asset typs */
    childAssets?: IAsset[];
    /** Additional settings for asset previews */
    additionalSettings?: IAdditionalPageSettings;
    /** Most recently selected tag */
    selectedTag: string;
    /** Tags locked for region labeling */
    lockedTags: string[];
    /** Size of the asset thumbnails to display in the side bar */
    thumbnailSize: ISize;
    /**
     * Whether or not the editor is in a valid state
     * State is invalid when a region has not been tagged
     */
    isValid: boolean;
    /** Whether the show invalid region warning alert should display */
    showInvalidRegionWarning: boolean;

    editUniqueIdValue?: string;
}

function mapStateToProps(state: IApplicationState) {
    return {
        project: state.currentProject,
        appSettings: state.appSettings,
        currentUser: state.currentUser,
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(projectActions, dispatch),
        applicationActions: bindActionCreators(applicationActions, dispatch),
        externalProjectActions: bindActionCreators(externalProjectActions, dispatch),
        appErrorActions: bindActionCreators(appErrorActions, dispatch),
    };
}

/**
 * @name - Editor Page
 * @description - Page for adding/editing/removing tags to assets
 */
@connect(mapStateToProps, mapDispatchToProps)
export default class EditorPage extends React.Component<IEditorPageProps, IEditorPageState> {
    public state: IEditorPageState = {
        selectedTag: null,
        lockedTags: [],
        selectionMode: SelectionMode.NONE,
        assets: [],
        childAssets: [],
        editorMode: EditorMode.Select,
        additionalSettings: {
            videoSettings: (this.props.project) ? this.props.project.videoSettings : null,
            activeLearningSettings: (this.props.project) ? this.props.project.activeLearningSettings : null,
        },
        thumbnailSize: this.props.appSettings.thumbnailSize || {width: 175, height: 155},
        isValid: true,
        showInvalidRegionWarning: false,
    };

    private activeLearningService: ActiveLearningService = null;
    private loadingProjectAssets: boolean = false;
    private toolbarItems: IToolbarItemRegistration[] = ToolbarItemFactory.getToolbarItems();
    private canvas: RefObject<Canvas> = React.createRef();
    private renameTagConfirm: React.RefObject<Confirm> = React.createRef();
    private deleteTagConfirm: React.RefObject<Confirm> = React.createRef();
    private markProcessFinishedConfirm: React.RefObject<Confirm> = React.createRef();
    private revertProcessFinishedConfirm: React.RefObject<Confirm> = React.createRef();
    private resetOriginalRegionsConfirm: React.RefObject<Confirm> = React.createRef();

    public async componentDidMount() {
        const projectId = this.props.match.params["projectId"];
        if (this.props.project) {
            // project already there, coming from overview
            await this.loadProjectAssets();
        } else if (projectId) {
            // project missing, coming from url - load from backend since it is the only source of truth
            const project = await this.props.externalProjectActions.loadProject(projectId);
            if (!project) {
                this.props.appErrorActions.showError(
                    {
                        errorCode: ErrorCode.ProjectDoesntExist,
                        message: `Project with id ${projectId} not found`
                    });
                return Promise.reject();
            }
            await this.props.actions.loadProject(project);
        }
        this.activeLearningService = new ActiveLearningService(this.props.project.activeLearningSettings);
    }

    public async componentDidUpdate(prevProps: Readonly<IEditorPageProps>) {
        if (this.props.project && this.state.assets.length === 0) {
            await this.loadProjectAssets();
        }

        // Navigating directly to the page via URL (ie, http://vott/projects/a1b2c3dEf/edit) sets the default state
        // before props has been set, this updates the project and additional settings to be valid once props are
        // retrieved.
        if (this.props.project && !prevProps.project) {
            this.setState({
                additionalSettings: {
                    videoSettings: (this.props.project) ? this.props.project.videoSettings : null,
                    activeLearningSettings: (this.props.project) ? this.props.project.activeLearningSettings : null,
                },
            });
        }

        if (this.props.project && prevProps.project && this.props.project.tags !== prevProps.project.tags) {
            this.updateRootAssets();
        }
    }

    public render() {
        const {project} = this.props;
        const {assets, selectedAsset} = this.state;
        const rootAssets = assets.filter((asset) => !asset.parent);

        if (!project) {
            return (<div>Loading...</div>);
        }

        return (
            <div className="editor-page">
                {[...Array(10).keys()].map((index) => {
                    return (<KeyboardBinding
                        displayName={strings.editorPage.tags.hotKey.apply}
                        key={index}
                        keyEventType={KeyEventType.KeyDown}
                        accelerators={[`${index}`]}
                        icon={"fa-tag"}
                        handler={this.handleTagHotKey}/>);
                })}
                {[...Array(10).keys()].map((index) => {
                    return (<KeyboardBinding
                        displayName={strings.editorPage.tags.hotKey.lock}
                        key={index}
                        keyEventType={KeyEventType.KeyDown}
                        accelerators={[`CmdOrCtrl+${index}`]}
                        icon={"fa-lock"}
                        handler={this.handleCtrlTagHotKey}/>);
                })}
                <SplitPane split="vertical"
                           defaultSize={this.state.thumbnailSize.width}
                           minSize={100}
                           maxSize={400}
                           paneStyle={{display: "flex"}}
                           onChange={this.onSideBarResize}
                           onDragFinished={this.onSideBarResizeComplete}>
                    <div className="editor-page-sidebar bg-lighter-1">
                        <EditorSideBar
                            assets={rootAssets}
                            selectedAsset={selectedAsset ? selectedAsset.asset : null}
                            onBeforeAssetSelected={this.onBeforeAssetSelected}
                            onAssetSelected={this.selectAsset}
                            thumbnailSize={this.state.thumbnailSize}
                        />
                    </div>
                    <div className="editor-page-content">
                        <div className="editor-page-content-main">
                            <div className="editor-page-content-main-header">
                                <EditorToolbar project={this.props.project}
                                               user={this.props.currentUser}
                                               items={this.toolbarItems}
                                               actions={this.props.actions}
                                               onToolbarItemSelected={this.onToolbarItemSelected}/>
                            </div>
                            <div className="editor-page-content-main-body" onClick={this.onPageClick}>
                                {selectedAsset &&
                                <Canvas
                                    ref={this.canvas}
                                    selectedAsset={this.state.selectedAsset}
                                    onAssetMetadataChanged={this.onAssetMetadataChanged}
                                    onCanvasRendered={this.onCanvasRendered}
                                    onSelectedRegionsChanged={this.onSelectedRegionsChanged}
                                    editorMode={this.state.editorMode}
                                    selectionMode={this.state.selectionMode}
                                    project={this.props.project}
                                    lockedTags={this.state.lockedTags}>
                                    <AssetPreview
                                        additionalSettings={this.state.additionalSettings}
                                        autoPlay={true}
                                        controlsEnabled={this.state.isValid}
                                        onBeforeAssetChanged={this.onBeforeAssetSelected}
                                        onChildAssetSelected={this.onChildAssetSelected}
                                        asset={this.state.selectedAsset.asset}
                                        childAssets={this.state.childAssets}/>
                                </Canvas>
                                }
                            </div>
                        </div>
                        <div className="editor-page-right-sidebar">
                            <TagInput
                                tags={this.props.project.tags}
                                lockedTags={this.state.lockedTags}
                                selectedRegions={this.state.selectedRegions}
                                onChange={this.onTagsChanged}
                                onLockedTagsChange={this.onLockedTagsChanged}
                                onTagClick={this.onTagClicked}
                                onCtrlTagClick={this.onCtrlTagClicked}
                                onTagRenamed={this.confirmTagRenamed}
                                onTagDeleted={this.confirmTagDeleted}
                                onUniqueIdChanged={this.onUniqueIdChanged}
                            />
                            <div className="tag-input-text-input-row search-input">
                                <input
                                    disabled={!this.state.selectedRegions || this.state.selectedRegions.length == 0 || this.state.selectedRegions[0].tags.length == 0}
                                    className="tag-search-box"
                                    type="text"
                                    onKeyDown={this.onEditUniqueIdKeyDown}
                                    onChange={(e) => this.onUniqueIdChanging(e.target.value)}
                                    value={this.state.editUniqueIdValue || ''}
                                    placeholder={strings.editorPage.editUniqueId}
                                    autoFocus={true}
                                />
                                <i className="tag-row-icon fas fa-edit"/>
                            </div>
                            <CarmanagerLinks vin={this.props.project.vin}/>
                        </div>
                        <Confirm title={strings.editorPage.tags.rename.title}
                                 ref={this.renameTagConfirm}
                                 message={strings.editorPage.tags.rename.confirmation}
                                 confirmButtonColor="danger"
                                 onConfirm={this.onTagRenamed}/>
                        <Confirm title={strings.editorPage.tags.delete.title}
                                 ref={this.deleteTagConfirm}
                                 message={strings.editorPage.tags.delete.confirmation}
                                 confirmButtonColor="danger"
                                 onConfirm={this.onTagDeleted}/>
                    </div>
                </SplitPane>

                <Confirm title=""
                         ref={this.markProcessFinishedConfirm}
                         message={strings.editorPage.project.markFinishedConfirmation}
                         confirmButtonColor="danger"
                         onConfirm={() => this.onMarkProjectFinished()}/>

                <Confirm title=""
                         ref={this.revertProcessFinishedConfirm}
                         message={strings.editorPage.project.revertFinishedConfirmation}
                         confirmButtonColor="danger"
                         onConfirm={() => this.onMarkProjectUnFinished()}/>

                <Confirm title=""
                         ref={this.resetOriginalRegionsConfirm}
                         message={strings.editorPage.project.resetOriginalRegionsConfirmation}
                         confirmButtonColor="danger"
                         onConfirm={() => this.onRestoreOriginalRegions()}/>

                <Alert show={this.state.showInvalidRegionWarning}
                       title={strings.editorPage.messages.enforceTaggedRegions.title}
                    // tslint:disable-next-line:max-line-length
                       message={strings.editorPage.messages.enforceTaggedRegions.description}
                       closeButtonColor="info"
                       onClose={() => this.setState({showInvalidRegionWarning: false})}/>
            </div>
        );
    }

    private onPageClick = () => {
        this.setState({
            selectedRegions: [],
        }, () => this.canvas.current.unselectRegions());
    }

    /**
     * Called when the asset side bar is resized
     * @param newWidth The new sidebar width
     */
    private onSideBarResize = (newWidth: number) => {
        this.setState({
            thumbnailSize: {
                width: newWidth,
                height: newWidth / (4 / 3),
            },
        }, () => this.canvas.current.forceResize());
    }

    /**
     * Called when the asset sidebar has been completed
     */
    private onSideBarResizeComplete = () => {
        const appSettings = {
            ...this.props.appSettings,
            thumbnailSize: this.state.thumbnailSize,
        };

        this.props.applicationActions.saveAppSettings(appSettings);
    }

    /**
     * Called when a tag from footer is clicked
     * @param tag Tag clicked
     */
    private onTagClicked = (tag: ITag): void => {
        this.setState({
            selectedTag: tag.name,
            lockedTags: [],
        }, () => this.canvas.current.applyTag(tag.name));
    }

    /**
     * Open confirm dialog for tag renaming
     */
    private confirmTagRenamed = (tagName: string, newTagName: string): void => {
        this.renameTagConfirm.current.open(tagName, newTagName);
    }

    /**
     * Renames tag in assets and project, and saves files
     * @param tagName Name of tag to be renamed
     * @param newTagName New name of tag
     */
    private onTagRenamed = async (tagName: string, newTagName: string): Promise<void> => {
        const assetUpdates = await this.props.actions.updateProjectTag(this.props.project, tagName, newTagName);
        const selectedAsset = assetUpdates.find((am) => am.asset.id === this.state.selectedAsset.asset.id);

        if (selectedAsset) {
            if (selectedAsset) {
                this.setState({selectedAsset});
            }
        }
    }

    /**
     * Open Confirm dialog for tag deletion
     */
    private confirmTagDeleted = (tagName: string): void => {
        this.deleteTagConfirm.current.open(tagName);
    }

    /**
     * Removes tag from assets and projects and saves files
     * @param tagName Name of tag to be deleted
     */
    private onTagDeleted = async (tagName: string): Promise<void> => {
        const assetUpdates = await this.props.actions.deleteProjectTag(this.props.project, tagName);
        const selectedAsset = assetUpdates.find((am) => am.asset.id === this.state.selectedAsset.asset.id);

        if (selectedAsset) {
            this.setState({selectedAsset});
        }
    }

    private onCtrlTagClicked = (tag: ITag): void => {
        const locked = this.state.lockedTags;
        this.setState({
            selectedTag: tag.name,
            lockedTags: CanvasHelpers.toggleTag(locked, tag.name),
        }, () => this.canvas.current.applyTag(tag.name));
    }

    private getTagFromKeyboardEvent = (event: KeyboardEvent): ITag => {
        let key = parseInt(event.key, 10);
        if (isNaN(key)) {
            try {
                key = parseInt(event.key.split("+")[1], 10);
            } catch (e) {
                return;
            }
        }
        let index: number;
        const tags = this.props.project.tags;
        if (key === 0 && tags.length >= 10) {
            index = 9;
        } else if (key < 10) {
            index = key - 1;
        }
        if (index < tags.length) {
            return tags[index];
        }
        return null;
    }

    /**
     * Listens for {number key} and calls `onTagClicked` with tag corresponding to that number
     * @param event KeyDown event
     */
    private handleTagHotKey = (event: KeyboardEvent): void => {
        const tag = this.getTagFromKeyboardEvent(event);
        if (tag) {
            this.onTagClicked(tag);
        }
    }

    private handleCtrlTagHotKey = (event: KeyboardEvent): void => {
        const tag = this.getTagFromKeyboardEvent(event);
        if (tag) {
            this.onCtrlTagClicked(tag);
        }
    }

    /**
     * Raised when a child asset is selected on the Asset Preview
     * ex) When a video is paused/seeked to on a video
     */
    private onChildAssetSelected = async (childAsset: IAsset) => {
        if (this.state.selectedAsset && this.state.selectedAsset.asset.id !== childAsset.id) {
            await this.selectAsset(childAsset);
        }
    }

    /**
     * Returns a value indicating whether the current asset is taggable
     */
    private isTaggableAssetType = (asset: IAsset): boolean => {
        return asset.type !== AssetType.Unknown && asset.type !== AssetType.Video;
    }

    /**
     * Raised when the selected asset has been changed.
     * This can either be a parent or child asset
     */
    private onAssetMetadataChanged = async (assetMetadata: IAssetMetadata): Promise<void> => {
        // If the asset contains any regions without tags, don't proceed.
        const regionsWithoutTags = assetMetadata.regions.filter((region) => region.tags.length === 0);

        if (regionsWithoutTags.length > 0) {
            this.setState({isValid: false});
            return;
        }

        const initialState = assetMetadata.asset.state;

        // The root asset can either be the actual asset being edited (ex: VideoFrame) or the top level / root
        // asset selected from the side bar (image/video).
        const rootAsset = {...(assetMetadata.asset.parent || assetMetadata.asset)};

        if (this.isTaggableAssetType(assetMetadata.asset)) {
            assetMetadata.asset.state = assetMetadata.regions.length > 0 ? AssetState.Tagged : AssetState.Visited;
        } else if (assetMetadata.asset.state === AssetState.NotVisited) {
            assetMetadata.asset.state = AssetState.Visited;
        }

        // Update root asset if not already in the "Tagged" state
        // This is primarily used in the case where a Video Frame is being edited.
        // We want to ensure that in this case the root video asset state is accurately
        // updated to match that state of the asset.
        if (rootAsset.id === assetMetadata.asset.id) {
            rootAsset.state = assetMetadata.asset.state;
        } else {
            const rootAssetMetadata = await this.props.actions.loadAssetMetadata(this.props.project, rootAsset);

            if (rootAssetMetadata.asset.state !== AssetState.Tagged) {
                rootAssetMetadata.asset.state = assetMetadata.asset.state;
                await this.props.actions.saveAssetMetadata(this.props.project, rootAssetMetadata);
            }

            rootAsset.state = rootAssetMetadata.asset.state;
        }

        // Only update asset metadata if state changes or is different
        if (initialState !== assetMetadata.asset.state || this.state.selectedAsset !== assetMetadata) {
            await this.props.actions.saveAssetMetadata(this.props.project, assetMetadata);
        }

        await this.props.actions.saveProject(this.props.project);

        const assetService = new AssetService(this.props.project);
        const childAssets = assetService.getChildAssets(rootAsset);

        // Find and update the root asset in the internal state
        // This forces the root assets that are displayed in the sidebar to
        // accurately show their correct state (not-visited, visited or tagged)
        const assets = [...this.state.assets];
        const assetIndex = assets.findIndex((asset) => asset.id === rootAsset.id);
        if (assetIndex > -1) {
            assets[assetIndex] = {
                ...rootAsset,
            };
        }

        this.setState({childAssets, assets, isValid: true});
    }

    /**
     * Raised when the asset binary has been painted onto the canvas tools rendering canvas
     */
    private onCanvasRendered = async (canvas: HTMLCanvasElement) => {
        // When active learning auto-detect is enabled
        // run predictions when asset changes
        if (this.props.project.activeLearningSettings.autoDetect && !this.state.selectedAsset.asset.predicted) {
            await this.predictRegions(canvas);
        }
    }

    private onSelectedRegionsChanged = (selectedRegions: IRegion[]) => {
        this.setState({
            editUniqueIdValue: selectedRegions && selectedRegions.length > 0 ? selectedRegions[0].memorizableUniqueId : null,
            selectedRegions,
        });
    }

    private onTagsChanged = async (tags) => {
        const project = {
            ...this.props.project,
            tags,
        };

        await this.props.actions.saveProject(project);
    }

    private onLockedTagsChanged = (lockedTags: string[]) => {
        this.setState({lockedTags});
    }

    private onToolbarItemSelected = async (toolbarItem: ToolbarItem): Promise<void> => {
        switch (toolbarItem.props.name) {
            case ToolbarItemName.ZoomIn:
                this.canvas.current.zoomIn();
                if (this.state.editorMode === EditorMode.Select || this.state.editorMode === EditorMode.None) {
                    this.setState({
                        selectionMode: SelectionMode.DRAG,
                        editorMode: EditorMode.Select,
                    });
                }
                break;
            case ToolbarItemName.ZoomOut:
                this.canvas.current.zoomOut();
                if (this.state.editorMode === EditorMode.Select || this.state.editorMode === EditorMode.None) {
                    this.setState({
                        selectionMode: SelectionMode.DRAG,
                        editorMode: EditorMode.Select,
                    });
                }
                break;
            case ToolbarItemName.DrawRectangle:
                this.setState({
                    selectionMode: SelectionMode.RECT,
                    editorMode: EditorMode.Rectangle,
                });
                break;
            case ToolbarItemName.DrawPolygon:
                this.setState({
                    selectionMode: SelectionMode.POLYGON,
                    editorMode: EditorMode.Polygon,
                });
                break;
            case ToolbarItemName.CopyRectangle:
                this.setState({
                    selectionMode: SelectionMode.COPYRECT,
                    editorMode: EditorMode.CopyRect,
                });
                break;
            case ToolbarItemName.SelectCanvas:
                this.setState({
                    selectionMode: SelectionMode.NONE,
                    editorMode: EditorMode.Select,
                });
                break;
            case ToolbarItemName.DragCanvas:
                this.setState({
                    selectionMode: SelectionMode.DRAG,
                    editorMode: EditorMode.Select,
                });
                break;
            case ToolbarItemName.ToggleAssetFinished:
                await this.toggleAssetFinished();
                break;
            case ToolbarItemName.PreviousAsset:
                await this.goToRootAsset(-1);
                break;
            case ToolbarItemName.NextAsset:
                await this.goToRootAsset(1);
                break;
            case ToolbarItemName.CopyRegions:
                this.canvas.current.copyRegions();
                break;
            case ToolbarItemName.CutRegions:
                this.canvas.current.cutRegions();
                break;
            case ToolbarItemName.PasteRegions:
                this.canvas.current.pasteRegions();
                break;
            case ToolbarItemName.RemoveAllRegions:
                this.canvas.current.confirmRemoveAllRegions();
                break;
            case ToolbarItemName.ActiveLearning:
                await this.predictRegions();
                break;
            case ToolbarItemName.MarkProjectFinished:
                this.markProcessFinishedConfirm.current.open();
                break;
            case ToolbarItemName.MarkProjectUnFinished:
                this.revertProcessFinishedConfirm.current.open();
                break;
            case ToolbarItemName.RestoreOriginalRegions:
                this.resetOriginalRegionsConfirm.current.open();
                break;
        }
    }

    private predictRegions = async (canvas?: HTMLCanvasElement) => {
        canvas = canvas || document.querySelector("canvas");
        if (!canvas) {
            return;
        }

        // Load the configured ML model
        if (!this.activeLearningService.isModelLoaded()) {
            let toastId: number = null;
            try {
                toastId = toast.info(strings.activeLearning.messages.loadingModel, {autoClose: false});
                await this.activeLearningService.ensureModelLoaded();
            } catch (e) {
                toast.error(strings.activeLearning.messages.errorLoadModel);
                return;
            } finally {
                toast.dismiss(toastId);
            }
        }

        // Predict and add regions to current asset
        try {
            const updatedAssetMetadata = await this.activeLearningService
                .predictRegions(canvas, this.state.selectedAsset);

            await this.onAssetMetadataChanged(updatedAssetMetadata);
            this.setState({selectedAsset: updatedAssetMetadata});
        } catch (e) {
            throw new AppError(ErrorCode.ActiveLearningPredictionError, "Error predicting regions");
        }
    }

    /**
     * Navigates to the previous / next root asset on the sidebar
     * @param direction Number specifying asset navigation
     */
    private goToRootAsset = async (direction: number) => {
        const selectedRootAsset = this.state.selectedAsset.asset.parent || this.state.selectedAsset.asset;
        const currentIndex = this.state.assets
            .findIndex((asset) => asset.id === selectedRootAsset.id);

        if (direction > 0) {
            await this.selectAsset(this.state.assets[Math.min(this.state.assets.length - 1, currentIndex + 1)]);
        } else {
            await this.selectAsset(this.state.assets[Math.max(0, currentIndex - 1)]);
        }
    }

    private onMarkProjectFinished() {
        const actions: [Promise<any>, Promise<any>] = [
            this.props.actions.exportProject(this.props.project),
            new ExternalProjectsService(this.props.currentUser).finishProject(this.props.project.id)
        ];
        Promise.all(actions)
            .then(() => {
                sscache.flush(); //Neuladen der Hauptseite erzwingen - nicht besonders sauber :(
                this.props.history.push("/");
            })
            .catch(e => {
                this.props.appErrorActions.showError({
                    errorCode: ErrorCode.ProjectFinishError,
                    message: "Error finishing project"
                });
            });
    }

    private onMarkProjectUnFinished() {
        const actions: [Promise<any>, Promise<any>] = [
            this.props.actions.exportProject(this.props.project),
            new ExternalProjectsService(this.props.currentUser).unfinishProject(this.props.project.id)
        ];
        Promise.all(actions)
            .then(() => {
                sscache.flush(); //Neuladen der Hauptseite erzwingen - nicht besonders sauber :(
                this.props.history.push("/");
            })
            .catch(e => {
                this.props.appErrorActions.showError({
                    errorCode: ErrorCode.ProjectFinishError,
                    message: "Error unfinishing project"
                });
            });
    }

    private onRestoreOriginalRegions() {

        const asset = {...this.state.selectedAsset};
        asset.regions = [...asset.regionsCopy];
        this.setState({
            selectedAsset: asset,
        }, async () => {
            await this.onAssetMetadataChanged(asset);
        });
    }

    private async toggleAssetFinished() {
        const selectedAsset = this.state.selectedAsset;
        if (selectedAsset) {
            selectedAsset.asset.finished = !selectedAsset.asset.finished;
            await this.onAssetMetadataChanged(selectedAsset);
            if (selectedAsset.asset.finished) {
                await this.goToRootAsset(1);
            }
        }
        return Promise.resolve();
    }

    private onBeforeAssetSelected = (): boolean => {
        if (!this.state.isValid) {
            this.setState({showInvalidRegionWarning: true});
        }

        return this.state.isValid;
    }

    private selectAsset = async (asset: IAsset): Promise<void> => {
        // Nothing to do if we are already on the same asset.
        if (this.state.selectedAsset && this.state.selectedAsset.asset.id === asset.id) {
            return;
        }

        if (this.canvas.current) {
            this.canvas.current.resetZoom();
        }

        if (!this.state.isValid) {
            this.setState({showInvalidRegionWarning: true});
            return;
        }

        const assetMetadata = await this.props.actions.loadAssetMetadata(this.props.project, asset);
        try {
            if (!assetMetadata.asset.size) {
                const assetProps = await HtmlFileReader.readAssetAttributes(asset);
                assetMetadata.asset.size = {width: assetProps.width, height: assetProps.height};
            }
        } catch (err) {
            console.warn("Error computing asset size");
        }

        this.setState({
            selectedAsset: assetMetadata,
        }, async () => {
            await this.onAssetMetadataChanged(assetMetadata);
        });
    }

    private loadProjectAssets = async (): Promise<void> => {
        if (this.loadingProjectAssets || this.state.assets.length > 0) {
            return;
        }

        this.loadingProjectAssets = true;

        // Get all root project assets
        const rootProjectAssets: IAsset[] = _.values(this.props.project.assets)
            .map(metadata => metadata.asset)
            .filter((asset) => !asset.parent);

        // Get all root assets from source asset provider
        const sourceAssets = await this.props.actions.loadAssets(this.props.project);

        // Merge and uniquify
        const rootAssets = _(rootProjectAssets)
            .concat(sourceAssets)
            .uniqBy((asset) => asset.id)
            .value()
            .sort(SORT_ASSETS_BY_CAMERA_AND_ORDER);

        const lastVisited = rootAssets.find((asset) => asset.id === this.props.project.lastVisitedAssetId);

        this.setState({
            assets: rootAssets,
        }, async () => {
            if (rootAssets.length > 0) {
                await this.selectAsset(lastVisited ? lastVisited : rootAssets[0]);
            }
            this.loadingProjectAssets = false;
        });
    }

    /**
     * Updates the root asset list from the project assets
     */
    private updateRootAssets = () => {
        const updatedAssets = [...this.state.assets];
        updatedAssets.forEach((asset) => {
            const projectAsset = this.props.project.assets[asset.id].asset;
            if (projectAsset) {
                asset.state = projectAsset.state;
            }
        });

        this.setState({assets: updatedAssets});
    }

    private onUniqueIdChanging = (value): void => {
        this.setState({
            editUniqueIdValue: value
        });
    }

    private onEditUniqueIdKeyDown = (event: ReactKeyboardEvent): void => {
        if (event.key === "Enter") {
            this.onUniqueIdChanged()
        }
    }

    private onUniqueIdChanged = () => {
        const value = this.state.editUniqueIdValue;
        const selectedRegions = this.state.selectedRegions;
        if (selectedRegions && selectedRegions.length > 0) {
            const uniqueRegionIds = this.props.project.uniqueRegionIds;
            const region = selectedRegions[0];
            region.memorizableUniqueId = value;
            region.uniqueId = (_.invert(uniqueRegionIds))[value] || shortid.generate();
            uniqueRegionIds[region.uniqueId] = value;
            const regionIndex = this.state.selectedAsset.regions.findIndex((r) => r.id === region.id);
            const newMetadata = Object.assign({}, this.state.selectedAsset);
            if (regionIndex > -1) {
                newMetadata.regions[regionIndex] = region;
            } else {
                newMetadata.regions.push(region);
            }
            this.canvas.current.updateCanvasToolsRegionTags();
            this.onAssetMetadataChanged(newMetadata);
        }
    }


}
