import React from "react";
import { Redirect, RouteComponentProps } from "react-router";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import ILoginActions, * as loginActions from "./loginActions";
import { IApplicationState } from "../../models/applicationState";
import Form, { FormValidation } from "react-jsonschema-form";
import CustomFieldTemplate from "../../react/components/common/customField/customFieldTemplate";
import { strings } from "../../common/strings";
import { IUser } from "./user";

const formSchema = {
        "required": [
            "username",
            "password"
        ],
        "type": "object",
        "properties": {
            "username": {
                "title": strings.login.username,
                "type": "string"
            },
            "password": {
                "title": strings.login.password,
                "type": "string"
            }
        }
    }
;
const uiSchema = {
    "username": {
        "ui:widget": "text"
    },
    "password": {
        "ui:widget": "password"
    }
};

export interface ILoginPageProps extends RouteComponentProps {
    actions: ILoginActions;
    currentUser?: IUser;
}

export interface ILoginPageState {
    formSchema: any;
    uiSchema: any;
    formData: IUser;
    classNames: string[];
}

function mapStateToProps(state: IApplicationState) {
    return {
        currentUser: state.currentUser
    };
}

function mapDispatchToProps(dispatch) {
    return {
        actions: bindActionCreators(loginActions, dispatch),
    };
}

@connect(mapStateToProps, mapDispatchToProps)
export default class LoginPage extends React.Component<ILoginPageProps, ILoginPageState> {

    constructor(props, context) {
        super(props, context);

        this.state = {
            classNames: ["needs-validation"],
            formSchema: {...formSchema},
            uiSchema: {...uiSchema},
            formData: {},
        };

        this.onFormSubmit = this.onFormSubmit.bind(this);
        this.onFormValidate = this.onFormValidate.bind(this);
    }


    render() {
        // @ts-ignore
        let {from} = this.props.location.state || {from: {pathname: "/ibm"}};

        if (this.props.currentUser) {
            return <Redirect to={from}/>;
        }

        return (
            <div>
                <p>You must log in to view the page at {from.pathname}</p>
                <Form
                    className={this.state.classNames.join(" ")}
                    showErrorList={false}
                    liveValidate={true}
                    noHtml5Validate={true}
                    FieldTemplate={CustomFieldTemplate}
                    validate={this.onFormValidate}
                    schema={this.state.formSchema}
                    uiSchema={this.state.uiSchema}
                    formData={this.state.formData}
                    onSubmit={this.onFormSubmit}>
                    <div>
                        <button className="btn btn-success mr-1" type="submit">{strings.login.loginButton}</button>
                    </div>
                </Form>
            </div>
        );
    }

    private onFormValidate(user: IUser, errors: FormValidation) {
        if (this.state.classNames.indexOf("was-validated") === -1) {
            this.setState({
                classNames: [...this.state.classNames, "was-validated"],
            });
        }

        return errors;
    }

    private onFormSubmit(form) {
        this.props.actions.login(form.formData);
        this.setState({
            formData: form.formData
        })
    }

}
