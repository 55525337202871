import {IExternalProject} from "./externalProject";
import {IAssetMetadata, IProject, SORT_ASSETS_BY_CAMERA_AND_ORDER} from "../../models/applicationState";
import {TomcomBackendService} from "../tomcomBackendService";
import {IUser} from "../login/user";
import {CancelToken} from "axios";
import _ from "lodash";
import packageJson from "../../../package.json";

export class ExternalProjectsService extends TomcomBackendService {

    constructor(user: IUser) {
        super(user);
    }

    public async loadProjects(dateFrom: string, dateTo: string, vin: string, backofficeStatus: string): Promise<IExternalProject[]> {
        const response = await this.http.get(`/projects?from=${dateFrom}&to=${dateTo}&vin=${vin}&backofficeStatus=${backofficeStatus}`);
        return response.data;
    }

    public async loadProject(id: string): Promise<IProject> {
        const result = await this.http.get(`/projects/${id}`);
        const project: IProject = result.data;
        //Version einfach auf aktuelle setzen, ist uns egal
        project.version = packageJson.version;
        this.createUniqueReqionsMap(project);
        this.createRegionsCopy(project);
        return project;
    }

    private createUniqueReqionsMap(project: IProject) {
        if (project.assets) {
            const uniqueRegionIds = {};
            let nextId = 1;
            _.values(project.assets)
                // Wir sollen die Assets genauso sortieren wie im Editor damit die fortlaufende Nummer am niedrigsten sind bei ersten Bildern
                .sort((a1, a2) => SORT_ASSETS_BY_CAMERA_AND_ORDER(a1.asset, a2.asset))
                .map(metadata => metadata.regions)
                .filter(regions => regions && regions.length > 0)
                .forEach(regions => {
                    regions.forEach(region => {
                        if (!uniqueRegionIds[region.uniqueId]) {
                            const memorizableUniqueId = (nextId++).toString();
                            uniqueRegionIds[region.uniqueId] = memorizableUniqueId;
                            region.memorizableUniqueId = memorizableUniqueId;
                        } else {
                            region.memorizableUniqueId = uniqueRegionIds[region.uniqueId];
                        }
                    });
                });
            project.uniqueRegionIds = uniqueRegionIds;
        }
    }

    //Für Zurücksetzen
    private createRegionsCopy(project: IProject) {
        if (project.assets) {
            _.values(project.assets)
                .forEach(asset => {
                    asset.regionsCopy = [...asset.regions];
                });
        }
    }

    public async finishProject(id: string): Promise<void> {
        await this.http.put(`/projects/${id}/finished`);
        return Promise.resolve();
    }

    public async unfinishProject(id: string): Promise<void> {
        await this.http.put(`/projects/${id}/revertFinished`);
        return Promise.resolve();
    }

    public async loadImage(cancelToken: CancelToken, projectId: string, imageId: string, thumbnail: boolean = false): Promise<any> {
        const result = await this.http.get(`/projects/${projectId}/images/${imageId}?thumbnail=${thumbnail}`, {
            responseType: 'arraybuffer',
            cancelToken: cancelToken,
            transformResponse: [function (data) {
                return new Buffer(data, 'binary').toString('base64');
            }],
        });
        return result.data;
    }


    public async uploadImageMetadata(projectId: string, metadata: IAssetMetadata): Promise<void> {
        await this.http.put(`/projects/${projectId}/images/${metadata.asset.id}`, metadata);
        return Promise.resolve();
    }
}
