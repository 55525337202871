/* eslint-disable */

/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

goog.exportSymbol('proto.tensorflow.BytesList', null, global);
goog.exportSymbol('proto.tensorflow.Feature', null, global);
goog.exportSymbol('proto.tensorflow.FeatureList', null, global);
goog.exportSymbol('proto.tensorflow.FeatureLists', null, global);
goog.exportSymbol('proto.tensorflow.Features', null, global);
goog.exportSymbol('proto.tensorflow.FloatList', null, global);
goog.exportSymbol('proto.tensorflow.Int64List', null, global);
goog.exportSymbol('proto.tensorflow.TFRecordsImageMessage', null, global);

/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.BytesList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tensorflow.BytesList.repeatedFields_, null);
};
goog.inherits(proto.tensorflow.BytesList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.BytesList.displayName = 'proto.tensorflow.BytesList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tensorflow.BytesList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.BytesList.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.BytesList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.BytesList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.BytesList.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueList: msg.getValueList_asB64()
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.BytesList}
 */
proto.tensorflow.BytesList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.BytesList;
  return proto.tensorflow.BytesList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.BytesList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.BytesList}
 */
proto.tensorflow.BytesList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Uint8Array} */ (reader.readBytes());
      msg.addValue(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.BytesList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.BytesList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.BytesList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.BytesList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueList_asU8();
  if (f.length > 0) {
    writer.writeRepeatedBytes(
      1,
      f
    );
  }
};


/**
 * repeated bytes value = 1;
 * @return {!(Array<!Uint8Array>|Array<string>)}
 */
proto.tensorflow.BytesList.prototype.getValueList = function() {
  return /** @type {!(Array<!Uint8Array>|Array<string>)} */ (jspb.Message.getRepeatedField(this, 1));
};


/**
 * repeated bytes value = 1;
 * This is a type-conversion wrapper around `getValueList()`
 * @return {!Array<string>}
 */
proto.tensorflow.BytesList.prototype.getValueList_asB64 = function() {
  return /** @type {!Array<string>} */ (jspb.Message.bytesListAsB64(
      this.getValueList()));
};


/**
 * repeated bytes value = 1;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getValueList()`
 * @return {!Array<!Uint8Array>}
 */
proto.tensorflow.BytesList.prototype.getValueList_asU8 = function() {
  return /** @type {!Array<!Uint8Array>} */ (jspb.Message.bytesListAsU8(
      this.getValueList()));
};


/** @param {!(Array<!Uint8Array>|Array<string>)} value */
proto.tensorflow.BytesList.prototype.setValueList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!(string|Uint8Array)} value
 * @param {number=} opt_index
 */
proto.tensorflow.BytesList.prototype.addValue = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.tensorflow.BytesList.prototype.clearValueList = function() {
  this.setValueList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.FloatList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tensorflow.FloatList.repeatedFields_, null);
};
goog.inherits(proto.tensorflow.FloatList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.FloatList.displayName = 'proto.tensorflow.FloatList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tensorflow.FloatList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.FloatList.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.FloatList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.FloatList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.FloatList.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueList: jspb.Message.getRepeatedFloatingPointField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.FloatList}
 */
proto.tensorflow.FloatList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.FloatList;
  return proto.tensorflow.FloatList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.FloatList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.FloatList}
 */
proto.tensorflow.FloatList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedFloat());
      msg.setValueList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.FloatList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.FloatList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.FloatList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.FloatList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueList();
  if (f.length > 0) {
    writer.writePackedFloat(
      1,
      f
    );
  }
};


/**
 * repeated float value = 1;
 * @return {!Array<number>}
 */
proto.tensorflow.FloatList.prototype.getValueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedFloatingPointField(this, 1));
};


/** @param {!Array<number>} value */
proto.tensorflow.FloatList.prototype.setValueList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.tensorflow.FloatList.prototype.addValue = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.tensorflow.FloatList.prototype.clearValueList = function() {
  this.setValueList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.Int64List = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tensorflow.Int64List.repeatedFields_, null);
};
goog.inherits(proto.tensorflow.Int64List, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.Int64List.displayName = 'proto.tensorflow.Int64List';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tensorflow.Int64List.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.Int64List.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.Int64List.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.Int64List} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.Int64List.toObject = function(includeInstance, msg) {
  var f, obj = {
    valueList: jspb.Message.getRepeatedField(msg, 1)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.Int64List}
 */
proto.tensorflow.Int64List.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.Int64List;
  return proto.tensorflow.Int64List.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.Int64List} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.Int64List}
 */
proto.tensorflow.Int64List.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = /** @type {!Array<number>} */ (reader.readPackedInt64());
      msg.setValueList(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.Int64List.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.Int64List.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.Int64List} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.Int64List.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getValueList();
  if (f.length > 0) {
    writer.writePackedInt64(
      1,
      f
    );
  }
};


/**
 * repeated int64 value = 1;
 * @return {!Array<number>}
 */
proto.tensorflow.Int64List.prototype.getValueList = function() {
  return /** @type {!Array<number>} */ (jspb.Message.getRepeatedField(this, 1));
};


/** @param {!Array<number>} value */
proto.tensorflow.Int64List.prototype.setValueList = function(value) {
  jspb.Message.setField(this, 1, value || []);
};


/**
 * @param {!number} value
 * @param {number=} opt_index
 */
proto.tensorflow.Int64List.prototype.addValue = function(value, opt_index) {
  jspb.Message.addToRepeatedField(this, 1, value, opt_index);
};


proto.tensorflow.Int64List.prototype.clearValueList = function() {
  this.setValueList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.Feature = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, proto.tensorflow.Feature.oneofGroups_);
};
goog.inherits(proto.tensorflow.Feature, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.Feature.displayName = 'proto.tensorflow.Feature';
}
/**
 * Oneof group definitions for this message. Each group defines the field
 * numbers belonging to that group. When of these fields' value is set, all
 * other fields in the group are cleared. During deserialization, if multiple
 * fields are encountered for a group, only the last value seen will be kept.
 * @private {!Array<!Array<number>>}
 * @const
 */
proto.tensorflow.Feature.oneofGroups_ = [[1,2,3]];

/**
 * @enum {number}
 */
proto.tensorflow.Feature.KindCase = {
  KIND_NOT_SET: 0,
  BYTES_LIST: 1,
  FLOAT_LIST: 2,
  INT64_LIST: 3
};

/**
 * @return {proto.tensorflow.Feature.KindCase}
 */
proto.tensorflow.Feature.prototype.getKindCase = function() {
  return /** @type {proto.tensorflow.Feature.KindCase} */(jspb.Message.computeOneofCase(this, proto.tensorflow.Feature.oneofGroups_[0]));
};



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.Feature.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.Feature.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.Feature} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.Feature.toObject = function(includeInstance, msg) {
  var f, obj = {
    bytesList: (f = msg.getBytesList()) && proto.tensorflow.BytesList.toObject(includeInstance, f),
    floatList: (f = msg.getFloatList()) && proto.tensorflow.FloatList.toObject(includeInstance, f),
    int64List: (f = msg.getInt64List()) && proto.tensorflow.Int64List.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.Feature}
 */
proto.tensorflow.Feature.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.Feature;
  return proto.tensorflow.Feature.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.Feature} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.Feature}
 */
proto.tensorflow.Feature.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tensorflow.BytesList;
      reader.readMessage(value,proto.tensorflow.BytesList.deserializeBinaryFromReader);
      msg.setBytesList(value);
      break;
    case 2:
      var value = new proto.tensorflow.FloatList;
      reader.readMessage(value,proto.tensorflow.FloatList.deserializeBinaryFromReader);
      msg.setFloatList(value);
      break;
    case 3:
      var value = new proto.tensorflow.Int64List;
      reader.readMessage(value,proto.tensorflow.Int64List.deserializeBinaryFromReader);
      msg.setInt64List(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.Feature.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.Feature.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.Feature} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.Feature.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getBytesList();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tensorflow.BytesList.serializeBinaryToWriter
    );
  }
  f = message.getFloatList();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.tensorflow.FloatList.serializeBinaryToWriter
    );
  }
  f = message.getInt64List();
  if (f != null) {
    writer.writeMessage(
      3,
      f,
      proto.tensorflow.Int64List.serializeBinaryToWriter
    );
  }
};


/**
 * optional BytesList bytes_list = 1;
 * @return {?proto.tensorflow.BytesList}
 */
proto.tensorflow.Feature.prototype.getBytesList = function() {
  return /** @type{?proto.tensorflow.BytesList} */ (
    jspb.Message.getWrapperField(this, proto.tensorflow.BytesList, 1));
};


/** @param {?proto.tensorflow.BytesList|undefined} value */
proto.tensorflow.Feature.prototype.setBytesList = function(value) {
  jspb.Message.setOneofWrapperField(this, 1, proto.tensorflow.Feature.oneofGroups_[0], value);
};


proto.tensorflow.Feature.prototype.clearBytesList = function() {
  this.setBytesList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.tensorflow.Feature.prototype.hasBytesList = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FloatList float_list = 2;
 * @return {?proto.tensorflow.FloatList}
 */
proto.tensorflow.Feature.prototype.getFloatList = function() {
  return /** @type{?proto.tensorflow.FloatList} */ (
    jspb.Message.getWrapperField(this, proto.tensorflow.FloatList, 2));
};


/** @param {?proto.tensorflow.FloatList|undefined} value */
proto.tensorflow.Feature.prototype.setFloatList = function(value) {
  jspb.Message.setOneofWrapperField(this, 2, proto.tensorflow.Feature.oneofGroups_[0], value);
};


proto.tensorflow.Feature.prototype.clearFloatList = function() {
  this.setFloatList(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.tensorflow.Feature.prototype.hasFloatList = function() {
  return jspb.Message.getField(this, 2) != null;
};


/**
 * optional Int64List int64_list = 3;
 * @return {?proto.tensorflow.Int64List}
 */
proto.tensorflow.Feature.prototype.getInt64List = function() {
  return /** @type{?proto.tensorflow.Int64List} */ (
    jspb.Message.getWrapperField(this, proto.tensorflow.Int64List, 3));
};


/** @param {?proto.tensorflow.Int64List|undefined} value */
proto.tensorflow.Feature.prototype.setInt64List = function(value) {
  jspb.Message.setOneofWrapperField(this, 3, proto.tensorflow.Feature.oneofGroups_[0], value);
};


proto.tensorflow.Feature.prototype.clearInt64List = function() {
  this.setInt64List(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.tensorflow.Feature.prototype.hasInt64List = function() {
  return jspb.Message.getField(this, 3) != null;
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.Features = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tensorflow.Features, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.Features.displayName = 'proto.tensorflow.Features';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.Features.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.Features.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.Features} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.Features.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureMap: (f = msg.getFeatureMap()) ? f.toObject(includeInstance, proto.tensorflow.Feature.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.Features}
 */
proto.tensorflow.Features.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.Features;
  return proto.tensorflow.Features.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.Features} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.Features}
 */
proto.tensorflow.Features.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFeatureMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.tensorflow.Feature.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.Features.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.Features.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.Features} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.Features.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.tensorflow.Feature.serializeBinaryToWriter);
  }
};


/**
 * map<string, Feature> feature = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.tensorflow.Feature>}
 */
proto.tensorflow.Features.prototype.getFeatureMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.tensorflow.Feature>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.tensorflow.Feature));
};


proto.tensorflow.Features.prototype.clearFeatureMap = function() {
  this.getFeatureMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.FeatureList = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, proto.tensorflow.FeatureList.repeatedFields_, null);
};
goog.inherits(proto.tensorflow.FeatureList, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.FeatureList.displayName = 'proto.tensorflow.FeatureList';
}
/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */
proto.tensorflow.FeatureList.repeatedFields_ = [1];



if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.FeatureList.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.FeatureList.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.FeatureList} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.FeatureList.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureList: jspb.Message.toObjectList(msg.getFeatureList(),
    proto.tensorflow.Feature.toObject, includeInstance)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.FeatureList}
 */
proto.tensorflow.FeatureList.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.FeatureList;
  return proto.tensorflow.FeatureList.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.FeatureList} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.FeatureList}
 */
proto.tensorflow.FeatureList.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tensorflow.Feature;
      reader.readMessage(value,proto.tensorflow.Feature.deserializeBinaryFromReader);
      msg.addFeature(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.FeatureList.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.FeatureList.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.FeatureList} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.FeatureList.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureList();
  if (f.length > 0) {
    writer.writeRepeatedMessage(
      1,
      f,
      proto.tensorflow.Feature.serializeBinaryToWriter
    );
  }
};


/**
 * repeated Feature feature = 1;
 * @return {!Array<!proto.tensorflow.Feature>}
 */
proto.tensorflow.FeatureList.prototype.getFeatureList = function() {
  return /** @type{!Array<!proto.tensorflow.Feature>} */ (
    jspb.Message.getRepeatedWrapperField(this, proto.tensorflow.Feature, 1));
};


/** @param {!Array<!proto.tensorflow.Feature>} value */
proto.tensorflow.FeatureList.prototype.setFeatureList = function(value) {
  jspb.Message.setRepeatedWrapperField(this, 1, value);
};


/**
 * @param {!proto.tensorflow.Feature=} opt_value
 * @param {number=} opt_index
 * @return {!proto.tensorflow.Feature}
 */
proto.tensorflow.FeatureList.prototype.addFeature = function(opt_value, opt_index) {
  return jspb.Message.addToRepeatedWrapperField(this, 1, opt_value, proto.tensorflow.Feature, opt_index);
};


proto.tensorflow.FeatureList.prototype.clearFeatureList = function() {
  this.setFeatureList([]);
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.FeatureLists = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tensorflow.FeatureLists, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.FeatureLists.displayName = 'proto.tensorflow.FeatureLists';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.FeatureLists.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.FeatureLists.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.FeatureLists} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.FeatureLists.toObject = function(includeInstance, msg) {
  var f, obj = {
    featureListMap: (f = msg.getFeatureListMap()) ? f.toObject(includeInstance, proto.tensorflow.FeatureList.toObject) : []
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.FeatureLists}
 */
proto.tensorflow.FeatureLists.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.FeatureLists;
  return proto.tensorflow.FeatureLists.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.FeatureLists} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.FeatureLists}
 */
proto.tensorflow.FeatureLists.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = msg.getFeatureListMap();
      reader.readMessage(value, function(message, reader) {
        jspb.Map.deserializeBinary(message, reader, jspb.BinaryReader.prototype.readString, jspb.BinaryReader.prototype.readMessage, proto.tensorflow.FeatureList.deserializeBinaryFromReader, "");
         });
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.FeatureLists.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.FeatureLists.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.FeatureLists} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.FeatureLists.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getFeatureListMap(true);
  if (f && f.getLength() > 0) {
    f.serializeBinary(1, writer, jspb.BinaryWriter.prototype.writeString, jspb.BinaryWriter.prototype.writeMessage, proto.tensorflow.FeatureList.serializeBinaryToWriter);
  }
};


/**
 * map<string, FeatureList> feature_list = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.tensorflow.FeatureList>}
 */
proto.tensorflow.FeatureLists.prototype.getFeatureListMap = function(opt_noLazyCreate) {
  return /** @type {!jspb.Map<string,!proto.tensorflow.FeatureList>} */ (
      jspb.Message.getMapField(this, 1, opt_noLazyCreate,
      proto.tensorflow.FeatureList));
};


proto.tensorflow.FeatureLists.prototype.clearFeatureListMap = function() {
  this.getFeatureListMap().clear();
};



/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */
proto.tensorflow.TFRecordsImageMessage = function(opt_data) {
  jspb.Message.initialize(this, opt_data, 0, -1, null, null);
};
goog.inherits(proto.tensorflow.TFRecordsImageMessage, jspb.Message);
if (goog.DEBUG && !COMPILED) {
  proto.tensorflow.TFRecordsImageMessage.displayName = 'proto.tensorflow.TFRecordsImageMessage';
}


if (jspb.Message.GENERATE_TO_OBJECT) {
/**
 * Creates an object representation of this proto suitable for use in Soy templates.
 * Field names that are reserved in JavaScript and will be renamed to pb_name.
 * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
 * For the list of reserved names please see:
 *     com.google.apps.jspb.JsClassTemplate.JS_RESERVED_WORDS.
 * @param {boolean=} opt_includeInstance Whether to include the JSPB instance
 *     for transitional soy proto support: http://goto/soy-param-migration
 * @return {!Object}
 */
proto.tensorflow.TFRecordsImageMessage.prototype.toObject = function(opt_includeInstance) {
  return proto.tensorflow.TFRecordsImageMessage.toObject(opt_includeInstance, this);
};


/**
 * Static version of the {@see toObject} method.
 * @param {boolean|undefined} includeInstance Whether to include the JSPB
 *     instance for transitional soy proto support:
 *     http://goto/soy-param-migration
 * @param {!proto.tensorflow.TFRecordsImageMessage} msg The msg instance to transform.
 * @return {!Object}
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.TFRecordsImageMessage.toObject = function(includeInstance, msg) {
  var f, obj = {
    context: (f = msg.getContext()) && proto.tensorflow.Features.toObject(includeInstance, f),
    featureLists: (f = msg.getFeatureLists()) && proto.tensorflow.FeatureLists.toObject(includeInstance, f)
  };

  if (includeInstance) {
    obj.$jspbMessageInstance = msg;
  }
  return obj;
};
}


/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.tensorflow.TFRecordsImageMessage}
 */
proto.tensorflow.TFRecordsImageMessage.deserializeBinary = function(bytes) {
  var reader = new jspb.BinaryReader(bytes);
  var msg = new proto.tensorflow.TFRecordsImageMessage;
  return proto.tensorflow.TFRecordsImageMessage.deserializeBinaryFromReader(msg, reader);
};


/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.tensorflow.TFRecordsImageMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.tensorflow.TFRecordsImageMessage}
 */
proto.tensorflow.TFRecordsImageMessage.deserializeBinaryFromReader = function(msg, reader) {
  while (reader.nextField()) {
    if (reader.isEndGroup()) {
      break;
    }
    var field = reader.getFieldNumber();
    switch (field) {
    case 1:
      var value = new proto.tensorflow.Features;
      reader.readMessage(value,proto.tensorflow.Features.deserializeBinaryFromReader);
      msg.setContext(value);
      break;
    case 2:
      var value = new proto.tensorflow.FeatureLists;
      reader.readMessage(value,proto.tensorflow.FeatureLists.deserializeBinaryFromReader);
      msg.setFeatureLists(value);
      break;
    default:
      reader.skipField();
      break;
    }
  }
  return msg;
};


/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */
proto.tensorflow.TFRecordsImageMessage.prototype.serializeBinary = function() {
  var writer = new jspb.BinaryWriter();
  proto.tensorflow.TFRecordsImageMessage.serializeBinaryToWriter(this, writer);
  return writer.getResultBuffer();
};


/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.tensorflow.TFRecordsImageMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */
proto.tensorflow.TFRecordsImageMessage.serializeBinaryToWriter = function(message, writer) {
  var f = undefined;
  f = message.getContext();
  if (f != null) {
    writer.writeMessage(
      1,
      f,
      proto.tensorflow.Features.serializeBinaryToWriter
    );
  }
  f = message.getFeatureLists();
  if (f != null) {
    writer.writeMessage(
      2,
      f,
      proto.tensorflow.FeatureLists.serializeBinaryToWriter
    );
  }
};


/**
 * optional Features context = 1;
 * @return {?proto.tensorflow.Features}
 */
proto.tensorflow.TFRecordsImageMessage.prototype.getContext = function() {
  return /** @type{?proto.tensorflow.Features} */ (
    jspb.Message.getWrapperField(this, proto.tensorflow.Features, 1));
};


/** @param {?proto.tensorflow.Features|undefined} value */
proto.tensorflow.TFRecordsImageMessage.prototype.setContext = function(value) {
  jspb.Message.setWrapperField(this, 1, value);
};


proto.tensorflow.TFRecordsImageMessage.prototype.clearContext = function() {
  this.setContext(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.tensorflow.TFRecordsImageMessage.prototype.hasContext = function() {
  return jspb.Message.getField(this, 1) != null;
};


/**
 * optional FeatureLists feature_lists = 2;
 * @return {?proto.tensorflow.FeatureLists}
 */
proto.tensorflow.TFRecordsImageMessage.prototype.getFeatureLists = function() {
  return /** @type{?proto.tensorflow.FeatureLists} */ (
    jspb.Message.getWrapperField(this, proto.tensorflow.FeatureLists, 2));
};


/** @param {?proto.tensorflow.FeatureLists|undefined} value */
proto.tensorflow.TFRecordsImageMessage.prototype.setFeatureLists = function(value) {
  jspb.Message.setWrapperField(this, 2, value);
};


proto.tensorflow.TFRecordsImageMessage.prototype.clearFeatureLists = function() {
  this.setFeatureLists(undefined);
};


/**
 * Returns whether this field is set.
 * @return {!boolean}
 */
proto.tensorflow.TFRecordsImageMessage.prototype.hasFeatureLists = function() {
  return jspb.Message.getField(this, 2) != null;
};


goog.object.extend(exports, proto.tensorflow);
