import axios from "axios";
import { IUser } from "./user";

export class LoginService {

    public async login(user: IUser): Promise<IUser> {

        const url = "/users/whoami";
        const response = await axios.get(url, {
            auth: {
                username: user.username,
                password: user.password
            },
            validateStatus: function (status) {
                return status == 200 || status == 401; //wir wollen keine Exception für 401
            },
        });
        const authToken = response.headers["x-auth-token"];
        if (response.status == 200) {
            const user: IUser = response.data as IUser;
            user.authToken = authToken;

            //Prüfungen hie und da vermeiden ob Roles und Groups nicht null sind
            if (!user.roles) {
                user.roles = [];
            }
            if (!user.groups) {
                user.groups = [];
            }
            return user;
        }
        return null;
    }

}
