"use strict";

var _classCallCheck = require("/var/nobackup/jenkins/jobs/DDI Frontend/branches/release-2022-04.94hkrn/workspace/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/classCallCheck");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CanvasTools = void 0;

var Toolbar_1 = require("./CanvasTools/Toolbar/Toolbar");

var RegionsManager_1 = require("./CanvasTools/Region/RegionsManager");

var PointRegion_1 = require("./CanvasTools/Region/Point/PointRegion");

var RectRegion_1 = require("./CanvasTools/Region/Rect/RectRegion");

var AreaSelector_1 = require("./CanvasTools/Selection/AreaSelector");

var ISelectorSettings_1 = require("./CanvasTools/Interface/ISelectorSettings");

var CanvasTools_Filter_1 = require("./CanvasTools/CanvasTools.Filter");

var Rect_1 = require("./CanvasTools/Core/Rect");

var Point2D_1 = require("./CanvasTools/Core/Point2D");

var RegionData_1 = require("./CanvasTools/Core/RegionData");

var Tag_1 = require("./CanvasTools/Core/Tag");

var TagsDescriptor_1 = require("./CanvasTools/Core/TagsDescriptor");

var CanvasTools_Editor_1 = require("./CanvasTools/CanvasTools.Editor");

var RGBColor_1 = require("./CanvasTools/Core/Colors/RGBColor");

var LABColor_1 = require("./CanvasTools/Core/Colors/LABColor");

var XYZColor_1 = require("./CanvasTools/Core/Colors/XYZColor");

var HSLColor_1 = require("./CanvasTools/Core/Colors/HSLColor");

var Palette_1 = require("./CanvasTools/Core/Colors/Palette");

var Color_1 = require("./CanvasTools/Core/Colors/Color");

require("snapsvg-cjs");
/* import * as SNAPSVG_TYPE from "snapsvg";
declare var Snap: typeof SNAPSVG_TYPE; */


var CanvasTools = function CanvasTools() {
  _classCallCheck(this, CanvasTools);
};

exports.CanvasTools = CanvasTools;
/**
 * Core internal classes.
 */

CanvasTools.Core = {
  Rect: Rect_1.Rect,
  Point2D: Point2D_1.Point2D,
  RegionData: RegionData_1.RegionData,
  TagsDescriptor: TagsDescriptor_1.TagsDescriptor,
  Tag: Tag_1.Tag,
  Colors: {
    RGBColor: RGBColor_1.RGBColor,
    LABColor: LABColor_1.LABColor,
    XYZColor: XYZColor_1.XYZColor,
    HSLColor: HSLColor_1.HSLColor,
    Palette: Palette_1.Palette,
    Color: Color_1.Color
  }
};
/**
 * Classes for new region selection (creation).
 */

CanvasTools.Selection = {
  AreaSelector: AreaSelector_1.AreaSelector,
  SelectionMode: ISelectorSettings_1.SelectionMode
};
/**
 * Classes for regions management.
 */

CanvasTools.Region = {
  RegionsManager: RegionsManager_1.RegionsManager,
  PointRegion: PointRegion_1.PointRegion,
  RectRegion: RectRegion_1.RectRegion
};
/**
 * Classes and functions to apply filters to the source image.
 */

CanvasTools.Filters = {
  InvertFilter: CanvasTools_Filter_1.InvertFilter,
  GrayscaleFilter: CanvasTools_Filter_1.GrayscaleFilter,
  BlurDiffFilter: CanvasTools_Filter_1.BlurDiffFilter,
  ContrastFilter: CanvasTools_Filter_1.ContrastFilter,
  BrightnessFilter: CanvasTools_Filter_1.BrightnessFilter,
  SaturationFilter: CanvasTools_Filter_1.SaturationFilter
};
/**
 * The Editor component.
 */

CanvasTools.Editor = CanvasTools_Editor_1.Editor;
/**
 * The Toolbar component.
 */

CanvasTools.Toolbar = Toolbar_1.Toolbar;
/* CSS */

require("./../css/canvastools.css");