import React, { SyntheticEvent } from "react";
import { AutoSizer, List } from "react-virtualized";
import { AssetState, IApplicationState, IAsset, IProject, ISize } from "../../../../models/applicationState";
import { AssetPreview } from "../../common/assetPreview/assetPreview";
import { strings } from "../../../../common/strings";
import { connect } from "react-redux";
import Checkbox from 'rc-checkbox';
import 'rc-checkbox/assets/index.css';

/**
 * Properties for Editor Side Bar
 * @member assets - Array of assets to be previewed
 * @member onAssetSelected - Function to call when asset from side bar is selected
 * @member selectedAsset - Asset initially selected
 * @member thumbnailSize - The size of the asset thumbnails
 */
export interface IEditorSideBarProps {
    assets: IAsset[];
    project?: IProject;
    onAssetSelected: (asset: IAsset) => void;
    onBeforeAssetSelected?: () => boolean;
    selectedAsset?: IAsset;
    thumbnailSize?: ISize;
}

/**
 * State for Editor Side Bar
 * @member selectedAsset - Asset selected from side bar
 */
export interface IEditorSideBarState {
    assets: IAsset[];
    cameraPositions: string[];
    showAssetsWithTagsOnly: boolean;
    scrollToIndex: number;
    selectedCameraPosition?: string;
}

function mapStateToProps(state: IApplicationState) {
    return {
        project: state.currentProject,
    };
}


const HIDE_NOT_TAGGED_KEY = "hideNotTaggedAssets";

/**
 * @name - Editor Side Bar
 * @description - Side bar for editor page
 */
@connect(mapStateToProps)
export default class EditorSideBar extends React.Component<IEditorSideBarProps, IEditorSideBarState> {

    private listRef: React.RefObject<List> = React.createRef();

    public state: IEditorSideBarState = {
        assets: [],
        cameraPositions: [],
        showAssetsWithTagsOnly: JSON.parse(localStorage.getItem(HIDE_NOT_TAGGED_KEY) || "false"),
        scrollToIndex: this.props.selectedAsset
            ? this.props.assets.findIndex((asset) => asset.id === this.props.selectedAsset.id)
            : 0,
    };


    public render() {

        return (
            <div className="editor-page-sidebar-nav">
                <AutoSizer>
                    {({height, width}) => (
                        <div>
                            <select value={this.state.selectedCameraPosition}
                                    onChange={this.onCameraFilterChange}>
                                <option value="">{strings.editorPage.cameraPositionFilter.allCameras}</option>
                                {this.state.cameraPositions
                                     .sort((i1, i2) => i1.localeCompare(i2))
                                     .map((item) => <option key={item} value={item}>{item}</option>)}
                            </select>
                            <label>
                                <Checkbox checked={this.state.showAssetsWithTagsOnly}
                                          onChange={this.onDamageFilterChange}
                                          disabled={false}/>
                                {strings.editorPage.hideNotTagged}
                            </label>
                            <List
                                ref={this.listRef}
                                className="asset-list"
                                height={height}
                                width={width}
                                rowCount={this.state.assets.length}
                                rowHeight={() => this.getRowHeight(width)}
                                rowRenderer={this.rowRenderer}
                                overscanRowCount={5}
                                scrollToIndex={this.state.scrollToIndex}/>
                        </div>
                    )}
                </AutoSizer>
            </div>
        );
    }

    public componentDidUpdate(prevProps: IEditorSideBarProps) {

        if (prevProps.assets !== this.props.assets) {
            this.filterAssets();
        }

        if (prevProps.thumbnailSize !== this.props.thumbnailSize) {
            this.listRef.current.recomputeRowHeights();
        }

        if (!prevProps.selectedAsset && !this.props.selectedAsset) {
            return;
        }

        if ((!prevProps.selectedAsset && this.props.selectedAsset) ||
            prevProps.selectedAsset.id !== this.props.selectedAsset.id) {
            this.selectAsset(this.props.selectedAsset);
        }
    }

    componentDidMount(): void {
        this.filterAssets();
    }

    private filterAssets() {
        const cameraPositions = [...new Set(
            this.props.assets
                .map((item) => item.cameraPosition)
                .filter((value) => value))
        ];

        const assets = this.props.assets.filter((value) =>
            (!this.state.showAssetsWithTagsOnly || value.state === AssetState.Tagged) &&
            (!this.state.selectedCameraPosition || this.state.selectedCameraPosition === value.cameraPosition)
        );

        this.setState({
            cameraPositions,
            assets
        });
    }

    private getRowHeight = (width: number) => {
        return width / (4 / 3) + 16;
    }

    private selectAsset = (selectedAsset: IAsset): void => {
        const scrollToIndex = this.state.assets.findIndex((asset) => asset.id === selectedAsset.id);

        this.setState({
            scrollToIndex,
        }, () => {
            this.listRef.current.forceUpdateGrid();
        });
    }

    private onAssetClicked = (asset: IAsset): void => {
        if (this.props.onBeforeAssetSelected) {
            if (!this.props.onBeforeAssetSelected()) {
                return;
            }
        }

        this.selectAsset(asset);
        this.props.onAssetSelected(asset);
    }

    private onCameraFilterChange = (e: SyntheticEvent) => {
        const target = e.target as HTMLSelectElement;

        const selectedCameraPosition = target.value === '' ? undefined : target.value;
        this.setState({
            selectedCameraPosition
        }, () => {
            this.filterAssets();
        });
    };

    private onDamageFilterChange = (event) => {
        localStorage.setItem(HIDE_NOT_TAGGED_KEY, event.target.checked);
        this.setState({
            showAssetsWithTagsOnly: event.target.checked
        }, () => {
            this.filterAssets();
        });
    };

    private rowRenderer = ({key, index, style}): JSX.Element => {
        const asset = this.state.assets[index];
        const selectedAsset = this.props.selectedAsset;

        return (
            <div key={key} style={style}
                 className={this.getAssetCssClassNames(asset, selectedAsset)}
                 onClick={() => this.onAssetClicked(asset)}>
                <div className="asset-item-image">
                    {this.renderBadges(asset)}
                    <AssetPreview asset={asset} loadThumbnail={true}/>
                </div>
                <div className="asset-item-metadata">
                    <span className="asset-filename" title={asset.name}>{asset.name}</span>
                    {asset.size &&
                    <span>
                            {asset.size.width} x {asset.size.height}
                        </span>
                    }
                </div>
            </div>
        );
    }

    private renderBadges = (asset: IAsset): JSX.Element => {
        const finishedBadge = <span className="badge badge-finished"><i className="fas fa-check"/></span>;
        switch (asset.state) {
            case AssetState.Tagged:
                return (
                    <React.Fragment>
                        {
                            asset.finished && finishedBadge
                        }
                        <span title={strings.editorPage.tagged}
                              className="badge badge-tagged">
                            <i className="fas fa-tag">&nbsp;{this.props.project.assets[asset.id].regions.length}</i>
                        </span>
                    </React.Fragment>
                );
            case AssetState.Visited:
                return (
                    <React.Fragment>
                        {
                            asset.finished && finishedBadge
                        }
                        <span title={strings.editorPage.visited}
                              className="badge badge-visited">
                            <i className="fas fa-eye"></i>
                        </span>
                    </React.Fragment>
                );
            default:
                return null;
        }
    }

    private getAssetCssClassNames = (asset: IAsset, selectedAsset: IAsset = null): string => {
        const cssClasses = ["asset-item"];
        if (selectedAsset && selectedAsset.id === asset.id) {
            cssClasses.push("selected");
        }

        return cssClasses.join(" ");
    }

}
