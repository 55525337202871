import axios from "axios";
import { IUser } from "./login/user";

//TODO handle session expiry
export class TomcomBackendService {

    protected readonly http;

    constructor(user: IUser) {
        this.http = axios.create({
            headers: {
                'x-auth-token': user.authToken
            }
        })
    }

    public static isSessionExpiredError(e: Error): boolean {
        return e && e.hasOwnProperty("response") && e["response"].status == 401;
    }
}
