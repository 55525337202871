/**
 * User: pavel
 * Date: 23.08.19
 * Time: 17:20
 */
import { CarmanagerDocument } from "./carmanagerDocument";

export enum CarmanagerBewertungStatus {
    FOUND = "FOUND",
    VEHICLE_NOT_FOUND = "VEHICLE_NOT_FOUND",
    EVALUATION_NOT_FOUND = "EVALUATION_NOT_FOUND",
    UNKNOWN = "UNKNOWN"
}

export class CarmanagerBewertung {

    public status: CarmanagerBewertungStatus;
    public id: string;
    public documents: CarmanagerDocument[];

}
